import React, { useEffect, useState } from "react";
import api from "../../api/Api";
import useApiRequest from "../../api/ApiRequest";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ScrollAnimation from "react-animate-on-scroll";

const BreastCancer = () => {
  const request = useApiRequest();
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    request({
      method: "get",
      url: api.HEADER_FOOTER,
    })?.then((response) => {
      setHeaderFooterData(response);
    });
  }, []);

  useEffect(() => {
    request({
      method: "get",
      url: api.BREAST_CANCER_PAGE,
    })?.then((response) => {
      setPageData(response[0]);
    });
  }, []);

  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <section className=" header-space">
        <div className="page-content-wrapper">
          <div className="container">
            <div className="page-title">
              <h1 className="section-heading">{pageData?.title?.rendered}</h1>
            </div>
            <div className="hero-image">
              <ScrollAnimation duration={2} animateOnce animateIn="animate__fadeIn">
                <img src={pageData?.acf?.breast_cancer_section?.banner_image} />
              </ScrollAnimation>
            </div>
            <div className="breast-cancer-page-title">
              <h1 className="breast-cancer-section-heading">{pageData?.acf?.breast_cancer_section?.title}</h1>
              <p className="breast-cancer-section-sub-title">{pageData?.acf?.breast_cancer_section?.page_content}</p>
            </div>
          </div>

          <div className="breast-cancer-content-wrapper">
            <div className="container">
              <div className="leadership-team-section">
                <ul className="breast-cancer-list ">
                  {pageData?.acf?.breast_cancer_section?.content &&
                    pageData?.acf?.breast_cancer_section?.content?.map((data, index) =>
                      index % 2 != 0 ? (
                        <li key={index}>
                          {data?.render_this === "Yes" ? (
                            <div className="breast-cancer-content-wrapper">
                              <div className="breast-cancer-content">
                                <div className="breast-cancer-title">{data?.title}</div>
                                <div className="breast-cancer-info" dangerouslySetInnerHTML={{ __html: data?.content }} />
                              </div><div className="breast-cancer-image">
                                <img src={data?.image} alt="" />
                              </div>
                            </div>
                          ) : null}
                        </li>
                      ) : (
                        <li key={index}>
                          {data?.render_this === "Yes" ? (

                            <div className="breast-cancer-content-wrapper">
                              <div className="breast-cancer-image">
                                <img src={data?.image} alt="" />
                              </div>
                              <div className="breast-cancer-content">
                                <div className="breast-cancer-title">{data?.title}</div>
                                <div className="breast-cancer-info" dangerouslySetInnerHTML={{ __html: data?.content }} />
                              </div>
                            </div>
                          ) : null}
                        </li>
                      )
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
      />
    </div>
  );
};

export default BreastCancer;
