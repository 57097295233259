import React, { useEffect, useState } from "react";
import api from "../../api/Api";
import useApiRequest from "../../api/ApiRequest";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import JobForm from "./JobForm";

const Career = () => {
  const request = useApiRequest();
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [jobFormVisible, setJobFormVisible] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");

  useEffect(() => {
    request({
      method: "get",
      url: api.HEADER_FOOTER,
    })?.then((response) => {
      setHeaderFooterData(response);
    });

    // Job Listing API
    request({
      method: "get",
      url: api.JOB_OPENING,
    })?.then((response) => {
      setJobData(response);
    });
  }, []);

  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <section className="career-page  header-space p-t-50">
        <div className="container ">
          <h1 className="section-heading">Career</h1>
          {jobData &&
            jobData?.map((job, index) => (
              <div className="job-box-wrapper" key={index}>
                <div className="job-title-wrapper">
                  <h3 className="job-title">{job?.title?.rendered ?? ""}</h3>
                  <div className="job-location">
                    <span></span>
                    <span className="job-location-text">
                      <span>
                        <img src="/assets/images/icons/map-marker.svg" />
                      </span>
                      {job?.acf?.job_locations}
                    </span>
                  </div>
                </div>
                <div className="job-position-wrapper">
                  {job?.acf?.labels?.no_of_position_label && job?.acf?.no_of_postions && (
                    <div className="number-of-position">
                      <span className="job-position-text">{job?.acf?.labels?.no_of_position_label ?? ""}</span>
                      <span className="job-position-number">{job?.acf?.no_of_postions ?? ""}</span>
                    </div>
                  )}
                  {job?.acf?.labels?.experience_label && job?.acf?.experience && (
                    <div className="experience-wrapper">
                      <span className="experience-text">{job?.acf?.labels?.experience_label ?? ""}</span>
                      <span className="experience-number">{job?.acf?.experience ?? ""}</span>
                    </div>
                  )}
                </div>
                <div className="keyword-wrapper">
                  <div className="keyword-text">{job?.acf?.labels?.keywords_label}</div>
                  {job?.acf?.keywords?.length > 0 &&
                    job?.acf?.keywords?.map((keyword, index) => (
                      <span className="job-keywords" key={index}>
                        {keyword?.keyword_name ?? ""}
                      </span>
                    ))}
                </div>
                <div className="job-description-wrapper">
                  <div className="job-description-title">{job?.acf?.labels?.job_description_label ?? ""}</div>
                  <div className="job-description" dangerouslySetInnerHTML={{ __html: job?.content?.rendered }}></div>
                </div>
                <div className="job-apply-btn">
                  <Button
                    size="large"
                    onClick={() => {
                      setJobFormVisible(true);
                      setSelectedJob(job?.title?.rendered);
                    }}
                  >
                    {job?.acf?.labels?.apply_button_label}
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </section>
      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
      />
      <Modal
        visible={jobFormVisible}
        setVisible={setJobFormVisible}
        width="700px"
        content={<JobForm selectedJob={selectedJob} setJobFormVisible={setJobFormVisible} />}
      />
    </div>
  );
};

export default Career;
