const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const characterLimit = (input, count) => (input.length > count ? `${input.substring(0, count)}...` : input);

const htmlToText = (html) => html.replace(/(<([^>]+)>)/g, "");

const isNotEmpty = (obj) => {
  return typeof obj !== "undefined" && obj !== null && Object.keys(obj).length > 0 && obj.constructor === Object;
};

const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

const isHtml = (str) => {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

export { capitalizeFirstLetter, characterLimit, htmlToText, isNotEmpty, camelize, isHtml };
