import React from "react";

const Button = ({ animation = false, size = "", btntype = "primary", ...props }) => {
  const className = `${btntype == "link" ? "sign-in-link" : "common-btn"} ${size == "small" ? "sign-up-btn" : size == "large" ? "btn-l" : ""} ${
    animation ? "animated headShake infinite go" : ""
  }`;
  return (
    <button className={className} {...props}>
      {props.children}
    </button>
  );
};
export default Button;
