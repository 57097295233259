import api from "../api/Api";
import useApiRequest from "../api/ApiRequest";

const request = useApiRequest();
export const headerFooterData = async () => {
    const response = await request({
        method: "get",
        url: api.HEADER_FOOTER,
    })
    return response;
}

export const countryCode = () => {
    const response = request({
        method: "get",
        url: api.COUNTRY_LIST,
    });
    return response;
}
const userService = {
    headerFooterData,
    countryCode
}

export default userService;