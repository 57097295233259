import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
const ConsultTelescientist = ({ content, is_beta, hidebutton }) => {
  const isBooleanRegistration = is_beta ?? false

  return (
    <div className="home-consult-wrapper">
      <section className="home-consult-scienctists">
        <div className="container animated zoomInDown">
          <div className="home-consult-scienctists-que">{content?.subtitle}</div>
          <h4 dangerouslySetInnerHTML={{ __html: content?.title }} />
          {!hidebutton && (
            <div className="consult-scienctists-cta">
              <Button onClick={isBooleanRegistration && Boolean(JSON.parse(isBooleanRegistration)) ? () => window.open('/beta-user-registration') : () => window.open(content?.button_link?.url)}>{content?.button_link?.title}</Button>
            </div>
          )}
          {/* <div className="d-flex justify-content-center support-tele">
            {content?.icons?.map((icon, index) => (
              <div className="single-support-tele" key={index}>
                <img src={icon?.logo} alt="256 Bit Encryption" />
                <p>{icon?.title}</p>
              </div>
            ))}
          </div> */}
        </div>
      </section>
      <div className="home-consult-bg-image">
        <img src={content?.background_image} />
      </div>
    </div>
  );
};
export default ConsultTelescientist;
