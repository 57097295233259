import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import api from "../api/Api";
import useApiRequest from "../api/ApiRequest";
import { newsLetterApi, recaptchaKey } from "../constant";
import { showToast } from "../redux/slice/ToastReducer";
import { capitalizeFirstLetter } from "../utils/utils";
import Input from "./Input";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";
import * as yup from "yup";
import { beta_registration } from "../config";
const Footer = ({
  logo = "assets/images/logo.svg",
  leftContent = "",
  copyRightText = "",
  socialIcons = [],
  copyRightMenu = [],
  footerMenu = [],
  newsLetterContent = {},
  noLinks = false,
  poweredBy = {},
}) => {
  const request = useApiRequest();
  const dispatch = useDispatch();
  const reCaptchaRef = useRef(null);

  const onSubmit = (values, resetForm, setSubmitting) => {
    values.status = "confirmed";
    setSubmitting(true);
    request({
      method: "post",
      url: api.NEWS_LETTER,
      data: values,
      headers: {
        Authorization: "Basic " + btoa(newsLetterApi.username + ":" + newsLetterApi.password),
      },
    })?.then((response) => {
      if (response?.email) {
        dispatch(showToast({ type: "success", message: "Successfully subscribed." }));
        resetForm();
        reCaptchaRef.current.execute();
        setSubmitting(false);
      }
    });
  };

  useEffect(() => {
    reCaptchaRef.current.execute();
  }, [reCaptchaRef]);

  return (
    <footer>
      <div className="container">
        <div className="footer-container d-flex">
          <div className="footer-left-container">
            <div className="footer-logo">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <p>{leftContent}</p>
            <ul className="footer-media d-flex">
              {socialIcons?.map((sIcon, index) => {
                return (
                  <li key={index}>
                    <div onClick={() => window.open(sIcon?.social_link?.url)}>
                      <img src={sIcon?.social_icon} alt="Facebook" />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="footer-right-container">
            {!noLinks ? (
              <ul className="footer-menu d-flex">
                {Object.keys(footerMenu).map((menuKey, index) => {
                  return (
                    <li key={index}>
                      <h5>{capitalizeFirstLetter(menuKey)}</h5>
                      {footerMenu[menuKey].map((menu, mIndex) =>
                        menu?.title == "Webinars" ? (
                          <Link to="#" key={mIndex} onClick={() => window.open(menu?.url)}>
                            {menu?.title}
                          </Link>
                        ) : (
                          <Link key={mIndex} to={menu?.url} target={menu?.target}>
                            {menu?.title}
                          </Link>
                        )
                      )}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="launch-soon footer-label">Launching Soon</div>
            )}
            <div className="newsletter-container">
              <h5>{newsLetterContent?.title}</h5>
              <p>{newsLetterContent?.subtitle}</p>

              <Formik
                initialValues={{ email: "", recaptcha: "" }}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={yup.object().shape({
                  recaptcha: yup.string().required(),
                  email: yup.string().email("Must be a valid email").required("Please fill email field."),
                })}
                onSubmit={(values, { resetForm, setSubmitting }) => onSubmit(values, resetForm, setSubmitting)}
              >
                {({ handleSubmit, handleChange, values, errors, setFieldValue, isSubmitting }) => {
                  return (
                    <>
                      <form onSubmit={handleSubmit} className="w-100">
                        <div className="newsletter-input w-100">
                          <Input
                            placeholder={newsLetterContent?.placeholder_text}
                            onChange={handleChange}
                            value={values?.email}
                            name="email"
                            icon={
                              <button type="submit" className="submit-button" disabled={isSubmitting}>
                                <img src="assets/images/right-line-arrow.svg" alt="" />
                              </button>
                            }
                          />
                        </div>
                        {errors["email"] && <div className="form-error-msg">{errors["email"]}</div>}
                        <ReCAPTCHA
                          size="invisible"
                          ref={reCaptchaRef}
                          sitekey={recaptchaKey?.sitekey}
                          onChange={(value) => {
                            setFieldValue("recaptcha", value);
                          }}
                        />
                      </form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
        <div className="footer-bottom d-flex">
          <div className="footer-bottom-links">
            <div className="copyright">{copyRightText}</div>
            <ul className="d-flex">
              {copyRightMenu?.map((menu, index) => (
                <li key={index}>
                  <Link to={menu?.url}>{menu?.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="d-flex powered_by">
            <div className="copyright powered_by_text">
              {poweredBy?.powered_by_text}
              <a href={poweredBy?.url} target="_blank">
                {poweredBy?.label}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
