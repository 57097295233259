import React, { useState, useEffect, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Button from "../../../components/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import parse from "html-react-parser";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const InspiringStoriesSection = ({ content, is_beta, slider, launchlable = false }) => {
  const isBooleanRegistration = is_beta ?? false

  return (
    <section id="home-inspiring-stories" className="home-inspiring-stories animatedParent">
      {launchlable && <div className="launch-soon text-center">Launching Soon</div>}
      <div className="container">
        <h3 className="section-heading">{content?.title}</h3>
        <div className="single-story">
          <ScrollAnimation duration={2} className="story-quote " animateOnce animateIn="animate__lightSpeedInLeft">
            <img src="assets/images/icons/quotes.svg" alt="" />
          </ScrollAnimation>

          <Swiper
            loop={false}
            spaceBetween={10}
            speed={500}
            ref={slider}
            pagination={false}
            slidesPerView={1}
            modules={[Autoplay, Navigation, Pagination]}
            navigation={true}
          >
            {content?.quotes?.map((story, index) =>
              (story?.quote_first && story?.person_name_first) || (story?.quote_sec && story?.person_name_sec) ? (
                <SwiperSlide className="swiper-slide" key={index}>
                  <div className="story-author">{story?.person_name_first}</div>
                  <div className="single-story-content">
                  <div className="paragraph-wrapper">{parse(story?.quote_first)}</div>
                  </div>
                  <div className="story-author">{story?.person_name_sec}</div>
                  <div className="single-story-content">
                    <div className="paragraph-wrapper">{parse(story?.quote_sec)}</div>
                  </div>
                </SwiperSlide>
              ) : null
            )}
            <div className="slider-text">Next Inspiring Stories</div>
          </Swiper>
        </div>
        {launchlable ? (
          <div className="home-inspiring-story-get-started text-center ">
            <div className="launch-soon">Launching Soon</div>
          </div>
        ) : (
          <ScrollAnimation
            duration={1}
            className="home-inspiring-story-get-started text-center "
            animateOnce
            animateIn="animate__backInUp"
          >
            <div className="paragraph-wrapper">{content?.message}</div>
            <Button onClick={isBooleanRegistration && Boolean(JSON.parse(isBooleanRegistration)) ? () => window.open('/beta-user-registration') : () => window.open(content?.button_link?.url)}>{content?.button_link?.title}</Button>
          </ScrollAnimation>
        )}
      </div>
    </section>
  );
};
export default InspiringStoriesSection;
