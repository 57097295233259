import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Button from "../../../components/Button";
import { characterLimit } from "../../../utils/utils";
import parse from "html-react-parser";
import { beta_registration } from "../../../config";

const WhySynaptical = ({ content, is_beta, setSliderCount, firstSlider, story, noLinks }) => {

  const [storyData, setStoryData] = useState([]);
  const isBooleanRegistration = is_beta ?? false

  useEffect(() => {
    if (story) {
      const storyContent = [];
      for (const item of story?.quotes ?? []) {
        if (item?.person_name_first && item?.quote_first)
          storyContent.push({ client_name: item?.person_name_first, story_content: item?.quote_first });
        if (item?.person_name_sec && item?.quote_sec)
          storyContent.push({ client_name: item?.person_name_sec, story_content: item?.quote_sec });
      }
      setStoryData(storyContent);
    }
  }, [story]);

  return (
    <section className="why-synaptical animatedParent">
      <div className="container ">
        <h3 className="section-heading">{content?.title}</h3>
        <div className="d-flex justify-content-space-between synaptical-section">
          <h4 className="section-sub-heading" dangerouslySetInnerHTML={{ __html: content?.subtitle }} />
          <div className="inspiring-story-wrapper">
            <div className="inspiring-story-section">
              <h2>{content?.inspiring_story_title}</h2>
              <div className="swiper">
                <Swiper
                  autoplay={{
                    delay: 6000,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  spaceBetween={10}
                  speed={1500}
                  ref={firstSlider}
                  pagination={{ dynamicBullets: true, clickable: true }}
                  slidesPerView={1}
                  modules={[Autoplay, Navigation, Pagination]}
                  navigation={false}
                >
                  {storyData?.map((story, index) => (
                    <SwiperSlide className="swiper-slide" key={index}>
                      <div className="client-name">{story?.client_name}</div>
                      <div className="client-story">
                        {parse(characterLimit(story?.story_content, 400))}
                        <div
                          className="d-flex justify-content-end cursor-pointer"
                          onClick={() => {
                            setSliderCount(index);
                            document.querySelector("#home-inspiring-stories").scrollIntoView({ behavior: "smooth" });
                          }}
                        >
                          Read more...
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            {!noLinks && (
              <div className="inspiring-story-button-wrapper">
                <Button size="large" onClick={isBooleanRegistration && Boolean(JSON.parse(isBooleanRegistration)) ? () => window.open('/beta-user-registration') : () => window.open(content?.signup_button_link?.url)}>
                  {content?.signup_button_link?.title}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default WhySynaptical;
