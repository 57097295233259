import React, { useEffect, useRef, useState } from "react";
import api from "../../api/Api";
import useApiRequest from "../../api/ApiRequest";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import Input from "../../components/Input";
import { recaptchaKey, usState } from "../../constant";
import Button from "../../components/Button";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { showToast } from "../../redux/slice/ToastReducer";
import '../../index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { PhoneInput } from "../../components/phoneInput";
import { InputError } from "../../components/inputError";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { CountryCodeSelect } from "../../components/countryCodeSelect";
import userService from "../../services/userService";
const BetaUserRegistration = () => {
  const request = useApiRequest();
  const dispatch = useDispatch();
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [betaUserRegistrationData, setBetaUserRegistrationData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryCode, setCountryCode] = useState([]);
  const [somethingElse, setSomethingElse] = useState(false);
  const [isIssueNotSelected, setIsIssueNotSelected] = useState(false);
  const reCaptchaRef = useRef(null);
  // const phoneRegExp = /^[6-9]\d{9}$/;
  const emaiRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const initialValues = {
    recaptcha: "",
    "first-name": "",
    "last-name": "",
    "email": "",
    "phone-number": "",
    "DateofBirth": "",
    "Gender": "Female",
    "State": "",
    "City": "",
    "Country": "233",
    "cell_country_code": "1",
    "Zip": "",
    "how-did-you-hear-about-us": "",
    "user_captcha_input": "",
    "i-agree": false
  };

  useEffect(() => {
    loadCaptchaEnginge(6, "#3959A2", "white");

    const fetchData = async () => {
      try {
        const response = await userService.headerFooterData();
        setHeaderFooterData(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchData();
    // Beta User Registartion Form page
    request({
      method: "get",
      url: api.BETA_USER_REGISTRATION_FORM_PAGE,
    })?.then((response) => {
      setBetaUserRegistrationData(response[0]);
    });

    // Country List

    getState(233);

  }, []);

  const getState = (countryId) => {
    request({
      method: "post",
      url: api.STATE_LIST,
      data: { country_id: countryId },
    })?.then((response) => {
      setStateList(response?.data);
    });
  };

  const getCity = (stateId) => {
    request({
      method: "post",
      url: api.CITY_LIST,
      data: { state_id: stateId },
    })?.then((response) => {
      setCityList(response?.data);
    });

  };

  // Contact form submit
  const onFormSubmit = (value, resetForm, setSubmitting) => {

    setSubmitting(true);
    const stateObj = stateList.find(d => d.id == value["State"]);
    const betaUserRegistrationData = {
      "first-name": value["first-name"] ?? "",
      "last-name": value["last-name"] ?? "",
      "email": value["email"] ?? "",
      "Phone-Number": value["phone-number"] ?? "",
      "DateofBirth": moment(value["DateofBirth"]).format("yyyy-MM-DD"),
      "Gender": value["Gender"] ?? "",
      "Country": "US",
      "City": value["City"] ?? "",
      "State": stateObj.name ?? "",
      "cell_country_code": value.cell_country_code || countryCode,
      "Zip": value["Zip"] ?? "",
      "how-did-you-hear-about-us": value["how-did-you-hear-about-us"] ?? "",
      "user_captcha_input": value["user_captcha_input"] ?? "",
      "i-agree": value["i-agree"] ?? "false",
    };
    const betaUserRegistrationForm = new FormData();
    Object.keys(betaUserRegistrationData).forEach((key) => betaUserRegistrationForm.append(key, betaUserRegistrationData[key]));
    request({
      method: "post",
      url: api.BETA_USER_REGISTRATION_FORM,
      data: betaUserRegistrationForm,
    })?.then((response) => {


      if (response?.invalid_fields.length == 0) {
        let user_captcha = document.getElementById('user_captcha_input').value;

        if (validateCaptcha(user_captcha) === true) {
          resetForm();
          // alert('Captcha Matched');
          loadCaptchaEnginge(6, "#3959A2", "white");
          document.getElementById('user_captcha_input').value = "";
          setSubmitting(false);
          dispatch(showToast({ type: "success", message: response.message }));
        }

        else {
          // alert('Captcha Does Not Match');
          setSubmitting(false);
          dispatch(showToast({ type: "error", message: "Captcha Does Not Match" }));
          document.getElementById('user_captcha_input').value = "";
        }

      } else {
        setSubmitting(true);
        dispatch(showToast({ type: "error", message: response.message }));
      }
    });

  };

  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <section className="beta-reg-form header-space m-b-50">
        <div className="container">
          <h1 className="section-heading">{betaUserRegistrationData?.title?.rendered}</h1>
          <div dangerouslySetInnerHTML={{ __html: betaUserRegistrationData?.content?.rendered }}></div>
          <div className="beta-reg-form-container d-flex">
            <div className="beta-reg-form-container">
              <Formik
                initialValues={initialValues}
                validationSchema={yup.object().shape({
                  recaptcha: yup.string().required(),
                  "first-name": yup
                    .string()
                    .trim()
                    .min(3, "First Name must be atleast 3 characters")
                    .max(50, "First Name cannot contain more than 50 characters")
                    .matches(/^[a-zA-Z\.]+$/, "Only alphabetic characters are allowed")
                    .required("Please enter your first name"),
                  "last-name": yup
                    .string()
                    .trim()
                    .min(3, "Last Name must be atleast 3 characters")
                    .max(50, "Last Name cannot contain more than 50 characters")
                    .matches(/^[a-zA-Z\.]+$/, "Only alphabetic characters are allowed")
                    .required("Please enter your last name"),
                  "email": yup.string().required("Please enter your email address").email('Please enter valid email address').matches(emaiRegx, "Please enter valid email address"),
                  "Gender": yup
                    .string()
                    .required("Please select your gender"),
                  "DateofBirth": yup
                    .string()
                    .required("Please select your date of birth"),
                  "State": yup.string().required("Please select state"),
                  "City": yup.string().required("Please select city"),
                  "Zip": yup.string().required("Please enter postal code"),
                  "cell_country_code": yup.string(),
                  "phone-number": yup
                    .string()
                    // .matches(phoneRegExp, "Please enter valid phone number")
                    .min(3, "Phone number must be atleast 3 digits."),
                  "how-did-you-hear-about-us": yup.string().min(3, "The field should contain a minimum of 3 characters and a maximum of 500 characters")
                    .max(500, "The field should contain a minimum of 3 characters and a maximum of 500 characters"),
                  "i-agree": yup
                    .boolean().oneOf([true], 'Please agree to terms and conditions'),
                  "user_captcha_input": yup.string().required("Please enter the captcha"),
                })}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { resetForm, setSubmitting }) => onFormSubmit(values, resetForm, setSubmitting)}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  setFieldValue,
                  setSubmitting,
                  isSubmitting,
                }) => {
                  console.log("errors", errors);
                  const onHandleBlur = (e) => {
                    if (!values.recaptcha) {
                      reCaptchaRef.current.execute();
                      setSubmitting(true);
                    }
                    handleBlur(e);
                  };
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="common-form-cell"> <label htmlFor="" className="m-b-5">Name</label>
                      </div>
                      <div className="common-form-row two-col-row justify-content-space-between d-flex">

                        <div className="common-form-cell">
                          <div className="input-box">
                            <Input
                              type="text"
                              name="first-name"
                              onBlur={onHandleBlur}
                              onChange={handleChange}
                              value={values["first-name"]}
                              placeholder="First Name"
                            />
                            {errors["first-name"] && <div className="form-error-msg">{errors["first-name"]}</div>}
                          </div>
                        </div>
                        <div className="common-form-cell">
                          {/* <label htmlFor="">Last Name</label> */}
                          <div className="input-box">
                            <Input
                              type="text"
                              name="last-name"
                              onBlur={onHandleBlur}
                              onChange={handleChange}
                              value={values["last-name"]}
                              placeholder="Last Name"
                            />
                            {errors["last-name"] && <div className="form-error-msg">{errors["last-name"]}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="common-form-row two-col-row justify-content-space-between d-flex no-flex">
                        <div className="common-form-cell">
                          <label htmlFor="UserId" className="m-b-5">
                            Email
                          </label>
                          <div className="input-with-icon">
                            <Input
                              type="text"
                              name="email"
                              onChange={handleChange}
                              onBlur={onHandleBlur}
                              value={values["email"]}
                              id="UserId"
                              placeholder=""
                            />
                            <div className="input-icon user-icon" style={{ zIndex: 0 }}></div>
                          </div>
                          {errors["email"] && <div className="form-error-msg">{errors["email"]}</div>}
                        </div>
                        <div className="common-form-cell">
                          <label htmlFor="" className="m-b-5">Phone Number <span style={{ fontSize: 14, color: '#7E7E7E' }}>(Optional)</span></label>
                          <div className="input-box input-flex">
                            <CountryCodeSelect
                              value={values?.cell_country_code}
                              onSelect={handleChange}
                              name="cell_country_code"
                            />
                            <PhoneInput
                              id="phone-number"
                              type="text"
                              label="Phone"
                              name="phone-number"
                              value={values['phone-number']}
                              onChange={handleChange}
                              onBlur={onHandleBlur}
                              showOptional={true}
                              showErrorMessage={false}
                              maxLength={10}
                            />
                          </div>
                          {errors["phone-number"] && <div className="form-error-msg">{errors["phone-number"]}</div>}

                        </div>
                      </div>

                      <div className="common-form-row">
                        <div className="common-form-cell">
                          <label htmlFor="" className="m-b-5">Date of Birth</label>
                          <div className="input-box">
                            <DatePicker
                              selected={values["DateofBirth"]}
                              onChange={(e) => setFieldValue("DateofBirth", e)}
                              maxDate={moment().subtract('30', 'day').toDate()}
                              minDate={moment().subtract('100', 'year').toDate()}
                              excludeOutOfBoundsTimes
                              name="DateofBirth"
                              dateFormat="MM-dd-yyyy"
                              placeholderText="MM-DD-YYYY"
                              showYearDropdown
                              yearDropdownItemNumber={70}
                              scrollableYearDropdown
                            />
                            <FontAwesomeIcon icon={faCalendarAlt} onClick={() => {
                              // Trigger the DatePicker when the calendar icon is clicked
                              document.getElementsByName("DateofBirth")[0].click();
                            }} />

                            {errors["DateofBirth"] && <div className="form-error-msg">{errors["DateofBirth"]}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="common-form-row">
                        <div className="common-form-cell">
                          <label htmlFor="" className="m-b-5">Gender:</label>
                          <div className="checkbox-wrapper">
                            <div className="custom-checkbox">
                              <input
                                type="radio"
                                checked={values["Gender"] == "Male"}
                                onChange={handleChange}
                                name="Gender"
                                value="Male"
                                id="male"
                              />
                              <label htmlFor="male" className="m-b-5">
                                <span>Male</span>
                              </label>
                            </div>
                            <div className="custom-checkbox">
                              <input
                                type="radio"
                                checked={values["Gender"] == "Female"}
                                onChange={handleChange}
                                name="Gender"
                                value="Female"
                                id="female"
                              />
                              <label htmlFor="female" className="m-b-5">
                                <span>Female</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="common-form-cell">
                        <label htmlFor="" className="m-b-5">Address</label>
                      </div>
                      <div className="common-form-row two-col-row justify-content-space-between d-flex no-flex">
                        <div className="common-form-cell">
                          <div className="input-box">
                            <div className="input-box-select">
                              <select
                                name="State"
                                id="state"
                                value={values["State"]}
                                onChange={(e) => {
                                  getCity(e.target.value);
                                  handleChange(e);
                                }}
                              >
                                <option className="option-color">State</option>
                                {stateList?.map((state, index) => (
                                  <option value={state?.id} key={index}>
                                    {state?.name}
                                  </option>
                                ))}
                              </select>
                              {errors["State"] && <div className="form-error-msg">{errors["State"]}</div>}

                            </div>
                          </div>
                        </div>
                        <div className="common-form-cell">
                          <div className="input-box">
                            <div className="input-box-select">
                              <select name="City" id="City" value={values["City"]} onChange={handleChange}>
                                <option>City</option>
                                {cityList?.map((city, index) => (
                                  <option value={city?.name} key={index}>
                                    {city?.name}
                                  </option>
                                ))}
                              </select>
                              {errors["City"] && <div className="form-error-msg">{errors["City"]}</div>}

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="common-form-row">

                        <div className="common-form-cell">
                          <div className="input-box">
                            <PhoneInput
                              id="Zip"
                              type="text"
                              name="Zip"
                              onBlur={onHandleBlur}
                              onChange={handleChange}
                              value={values["Zip"]}
                              placeholder="Postal Code"
                              showErrorMessage={false}
                              maxLength={5}
                            />
                            {errors["Zip"] && <div className="form-error-msg">{errors["Zip"]}</div>}
                          </div>
                        </div>
                      </div>

                      <div className="common-form-row d-flex">
                        <div className="common-form-cell">
                          <label htmlFor="UserId" className="m-b-5">
                            How did you hear about us? <span style={{ fontSize: 14, color: '#7E7E7E' }}>(Optional)</span>
                          </label>
                          <div className="input-box">
                            <textarea
                              className="contact-textarea"
                              onChange={handleChange}
                              onBlur={onHandleBlur}
                              value={values["how-did-you-hear-about-us"]}
                              name="how-did-you-hear-about-us"
                              id=""
                              cols="30"
                              rows="5"
                              maxLength={500}
                            ></textarea>
                            {errors["how-did-you-hear-about-us"] && <div className="form-error-msg">{errors["how-did-you-hear-about-us"]}</div>}
                          </div>
                        </div>
                      </div>

                      <div className="common-form-row two-col-row justify-content-space-between d-flex">
                        <div className="common-form-cell d-flex full-width" style={{ border: '1px red' }}>
                          <LoadCanvasTemplate reloadText="Reload" />
                          <div className="input-box" style={{}}>
                            <Input className="captcha-input" placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" onBlur={onHandleBlur}
                              onChange={handleChange}
                              value={values["user_captcha_input"]} />
                            {errors["user_captcha_input"] && <div className="form-error-msg captcha-error">{errors["user_captcha_input"]}</div>}
                          </div>
                        </div>


                      </div>
                      <div className="common-form-cell">
                        <Field name="i-agree" type="checkbox" />
                        <label htmlFor="termsAndConditions" className="m-b-5"> I agree to <a href="/terms-of-use" target="_blank">terms & conditions</a> and <a href="/privacy-policy" target="_blank">privacy policy.</a></label>
                        {errors["i-agree"] && <div className="form-error-msg">{errors["i-agree"]}</div>}
                      </div >
                      <div className="beta-reg-form-cta d-flex align-center ">
                        <ReCAPTCHA
                          size="invisible"
                          ref={reCaptchaRef}
                          sitekey={recaptchaKey?.sitekey}
                          onChange={(value) => {
                            setFieldValue("recaptcha", value);
                            setSubmitting(false);
                          }}
                        />
                        <Button className="common-btn" type="submit" disabled={isSubmitting}>
                          Submit
                        </Button>
                      </div>
                    </form >
                  );
                }}
              </Formik >
            </div >
          </div >
        </div >
      </section >

      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
      />
    </div >
  );
};

export default BetaUserRegistration;


