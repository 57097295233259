import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import api from "../../api/Api";
import useApiRequest from "../../api/ApiRequest";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const TeamScience = () => {
  const request = useApiRequest();
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    request({
      method: "get",
      url: api.HEADER_FOOTER,
    })?.then((response) => {
      setHeaderFooterData(response);
    });

    request({
      method: "get",
      url: api.TEAM_SCIENCE,
    })?.then((response) => {
      setPageData(response[0]);
    });
  }, []);
  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <section className="header-space m-b-50">
        <div className="container">
          <div className="page-content-wrapper">
            <div className="page-title">
              <h1 className="section-heading">{pageData?.acf?.team_scie?.title}</h1>
              <p className="section-sub-title">{pageData?.acf?.team_scie?.sub_title}</p>
            </div>
            <div className="hero-image">
              <img src={pageData?.acf?.team_scie?.image} />
            </div>
          </div>
          <div className="card-wrapper">
            {pageData?.acf?.page_content_settings?.content &&
              pageData?.acf?.page_content_settings?.content?.map((data, index) =>
                data?.render_this === "Yes" ? (
                  data?.position === "left" ? (
                    <div className="science-data-card" key={index}>
                      <ScrollAnimation
                        duration={2}
                        animateOnce
                        animateIn="animate__fadeInLeft"
                        className="science-content-left"
                      >
                        {data?.text?.map((content, tIndex) => (
                          <p className="" key={tIndex}>
                            {content?.text}
                          </p>
                        ))}
                      </ScrollAnimation>
                      <ScrollAnimation
                        duration={2}
                        animateOnce
                        animateIn="animate__fadeInRight"
                        className="science-image-right"
                      >
                        <img src={data?.image} alt="" />
                      </ScrollAnimation>
                    </div>
                  ) :
                    (
                      <div className="science-data-card" key={index}>
                        <ScrollAnimation
                          duration={2}
                          animateOnce
                          animateIn="animate__fadeInRight"
                          className="science-content-right"
                        >
                          {data?.text?.map((content, tIndex) => (
                            <p className="" key={tIndex}>
                              {content?.text}
                            </p>
                          ))}
                        </ScrollAnimation>
                        <ScrollAnimation
                          duration={2}
                          animateOnce
                          animateIn="animate__fadeInLeft"
                          className="science-image-left"
                        >
                          <img src={data?.image} alt="" />
                        </ScrollAnimation>
                      </div>
                    )
                ) : null

              )}
          </div>
        </div>
      </section>
      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
      />
    </div>
  );
};

export default TeamScience;
