import React from "react";

function CeoMessageSection({ content }) {
  return (
    <section id="ceo-message-section" className=" section-wrapper ceo-message-section animatedParent">
      <div className="content_box">
        <div className="content">
          <h3>{content?.title}</h3>
          <p>{content?.message}</p>
          <p>{content?.sub_title}</p>
        </div>
      </div>
      <div className="image_box">
        <img src={content?.image} />
      </div>
    </section>
  );
}

export default CeoMessageSection;
