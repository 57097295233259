import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { plansImage } from "../../../public/assets/images";
import api from "../../api/Api";
import useApiRequest from "../../api/ApiRequest";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { camelize, htmlToText } from "../../utils/utils";
import TsDetailsDrawer from "./TsDetailsDrawer";

const FindTeleScientist = () => {
  const request = useApiRequest();
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [telescientistList, setTelescientistList] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedTs, setSelectedTs] = useState();

  const plansImage = {
    benchToBedside: "/assets/images/bench-to-bedside-dashboard.svg",
    clinicalAccelerator: "/assets/images/scientific-discovery-dashboard.svg",
    scientificDiscovery: "/assets/images/clinical-accelerator-dashboard.svg",
  };

  const isBooleanRegistration = headerFooterData?.beta_registration ?? false

  useEffect(() => {
    request({
      method: "get",
      url: api.HEADER_FOOTER,
    })?.then((response) => {
      setHeaderFooterData(response);
    });

    request({
      method: "post",
      url: api.TELESCIENTIST,
    })?.then((response) => {
      setTelescientistList(response?.data);
    });
  }, []);

  const drawerHandler = (ts) => {
    setOpenDetails(true);
    let newSelectedTS = ts;
    if (newSelectedTS?.user_uuid?.review) {
      for (const review of newSelectedTS?.user_uuid?.review) {
        review.readMore = false;
      }
    }
    setSelectedTs(newSelectedTS);
  };

  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <section className="dashboard header-space p-t-50">
        <div className="container">
          <div className="dasboard-continer d-flex">
            <main className="dashboard-right-container">
              <h1 className="section-heading">Telescientists</h1>
              <section className="telescientist-sec">
                <ul className="ts-list d-flex">
                  {telescientistList &&
                    telescientistList?.map((ts, index) => (
                      <li key={index} className="d-flex direction-columan justify-content-between">
                        <div className="ts-layout">
                          <div>
                            <div className=" d-flex align-flex-start">
                              <img
                                style={{ width: "100px", height: "100%", borderRadius: "50px", aspectRatio: "1/1" }}
                                src={ts?.user_uuid?.profile_pic}
                                alt=""
                                onClick={() => drawerHandler(ts)}
                                className="ts-image"
                              />
                              <div className="ts-sort-details ">
                                <h4 className="ts-name" onClick={() => drawerHandler(ts)}>
                                  {ts?.user_uuid?.first_name +
                                    " " +
                                    ts?.user_uuid?.middle_name +
                                    " " +
                                    ts?.user_uuid?.last_name}
                                  {ts?.user_uuid?.highest_qualification
                                    ? ", " + ts?.user_uuid?.highest_qualification
                                    : ""}
                                </h4>
                                {/* <h3>{ts?.disease[0]?.disease_name}</h3> */}
                                <p>
                                  Work Experience: <span className="ts-exp">{ts?.user_uuid?.total_experience} Years</span>
                                </p>
                                <div className="single-cta m-t-5 d-flex align-center">
                                  <Link to="#" onClick={() => drawerHandler(ts)}>
                                    View Profile
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <h6 className="m-b-10 m-t-10">
                              {ts?.user_uuid?.current_designation ? htmlToText(ts?.user_uuid?.current_designation) : null}
                            </h6>
                            <p className="m-b-10">Specializes in:</p>
                            <h6 className="p-l-25">{ts?.user_uuid?.disease[0]?.disease_name}</h6>
                            <ul>
                              {ts?.user_uuid?.sub_disease?.split(",")?.map((item, i) => (
                                <li className="sub-disease-ts" key={i}>
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div>
                            {ts?.user_uuid?.session_plan?.length === 0 ? null : (
                              <div className="m-b-15 m-t-10">
                                <p className="m-b-15">Available for:</p>
                                <div className="d-flex flex-wrap gap-30">
                                  {ts?.user_uuid?.session_plan?.map((item, index) => (
                                    <div key={index} className="session-card cursor-pointer">
                                      <img src={plansImage[camelize(item?.plan_name)]} alt="Session" />
                                      <p className="session-text">{item?.plan_name}</p>
                                      {isBooleanRegistration && Boolean(JSON.parse(isBooleanRegistration)) ? null : <p className="session-price">${item?.plan_price}/session</p>}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </section>
            </main>
            <div className="sidebar-overlay"></div>
          </div>
        </div>
      </section>
      <TsDetailsDrawer
        setOpenDetails={setOpenDetails}
        openDetails={openDetails}
        tsData={selectedTs}
        setSelectedTs={setSelectedTs}
        headerFooterData={headerFooterData}
        setHeaderFooterData={setHeaderFooterData}
      />
      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
      />
    </div>
  );
};

export default FindTeleScientist;
