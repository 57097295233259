import React, { useEffect, useRef, useState } from "react";
import api from "../../api/Api";
import useApiRequest from "../../api/ApiRequest";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";
import * as yup from "yup";
import Input from "../../components/Input";
import { recaptchaKey, usState } from "../../constant";
import Button from "../../components/Button";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { showToast } from "../../redux/slice/ToastReducer";

const HelpCenter = () => {
  const request = useApiRequest();
  const dispatch = useDispatch();
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [helpCenterData, setHelpCenterData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [somethingElse, setSomethingElse] = useState(false);
  const [isIssueNotSelected, setIsIssueNotSelected] = useState(false);
  const reCaptchaRef = useRef(null);

  const initialValues = {
    recaptcha: "",
    "first-name": "",
    "last-name": "",
    "your-email": "",
    "your-message": "",
    cellphone: "",
    country: "",
    state: "",
    City: "",
    "comm-method": "email",
    "signing-up": [],
    "signing-in": [],
    "my-account-profile": [],
    "my-dashboard": [],
    "accessing-my-content": [],
    "telescience-sessions": [],
    "something-else": [],
    "something-else-text": "",
  };

  useEffect(() => {
    request({
      method: "get",
      url: api.HEADER_FOOTER,
    })?.then((response) => {
      setHeaderFooterData(response);
    });

    // Help Center
    request({
      method: "get",
      url: api.HELP_CENTER_PAGE,
    })?.then((response) => {
      setHelpCenterData(response[0]);
    });

    // Country List
    request({
      method: "get",
      url: api.COUNTRY_LIST,
    })?.then((response) => {
      setCountryList(response?.data);
    });
  }, []);

  const getState = (countryId) => {
    request({
      method: "post",
      url: api.STATE_LIST,
      data: { country_id: countryId },
    })?.then((response) => {
      setStateList(response?.data);
    });
  };

  const getCity = (cityId) => {
    request({
      method: "post",
      url: api.CITY_LIST,
      data: { state_id: cityId },
    })?.then((response) => {
      setCityList(response?.data);
    });
  };

  // Contact form submit
  const onFormSubmit = (value, resetForm, setSubmitting) => {
    setSubmitting(true);
    const issueList = [];
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (value[key] == "on") {
          issueList.push(key);
        }
      }
    }
    const helpCenterData = {
      "first-name": value["first-name"] ?? "",
      "last-name": value["last-name"] ?? "",
      "your-email": value["your-email"] ?? "",
      cellphone: value["cellphone"] ?? "",
      communication_method: value["comm-method"] ?? "",
      "call-time": value["call-time"] ?? "",
      issue: issueList ?? "",
      City: value["City"] ?? "",
      Country: countryList?.find((item) => item.id === Number(value["country"]))?.name ?? "",
      State: stateList?.find((item) => item.id === Number(value["state"]))?.name ?? "",
      "other-issue": value["something-else-text"] ?? "",
      comments: value["your-message"] ?? "",
    };
    const contactForm = new FormData();
    Object.keys(helpCenterData).forEach((key) => contactForm.append(key, helpCenterData[key]));
    request({
      method: "post",
      url: api.HELP_CENTER_FORM,
      data: contactForm,
    })?.then((response) => {
      resetForm();
      if (response?.invalid_fields.length == 0) {
        setSubmitting(false);
        dispatch(showToast({ type: "success", message: response.message }));
      }
    });
  };

  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <section className="contact-us header-space m-b-50">
        <div className="container">
          <h1 className="section-heading">{helpCenterData?.title?.rendered}</h1>
          <div dangerouslySetInnerHTML={{ __html: helpCenterData?.content?.rendered }} style={{ marginBottom: 15 }}></div>
          <div className="contact-us-container d-flex">
            <div className="contact-form-container">
              <Formik
                initialValues={initialValues}
                validate={(values) => {
                  const issueList = [];
                  for (var key in values) {
                    if (values.hasOwnProperty(key)) {
                      if (values[key] == "on") {
                        issueList.push(key);
                      }
                    }
                  }
                  if (issueList?.length == 0) {
                    setIsIssueNotSelected(true);
                    return;
                  } else {
                    setIsIssueNotSelected(false);
                  }
                }}
                validationSchema={yup.object().shape({
                  recaptcha: yup.string().required(),
                  "first-name": yup
                    .string()
                    .trim()
                    .max(15, "First name can not be more than 15 characters.")
                    .required("Please fill first name field."),
                  "something-else-text": somethingElse ? yup.string().trim().required("Please write something.") : "",
                  "last-name": yup
                    .string()
                    .trim()
                    .max(15, "Last name can not be more than 15 characters.")
                    .required("Please fill last name field."),
                  "your-email": yup.string().email("Must be a valid email").required("Please fill email field."),
                  "your-message": yup.string().max(1000, "Comment can not be more than 200 characters."),
                  country: yup.string().required("Please select country."),
                  state: yup.string().required("Please select state."),
                  City: yup.string().required("Please select city."),
                  cellphone: yup
                    .string()
                    .matches(/^[2-9]\d{9}$/, "Please enter valid cellphone")
                    .min(10, "Please enter valid cellphone")
                    .required("Please enter cellphone."),
                })}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { resetForm, setSubmitting }) => onFormSubmit(values, resetForm, setSubmitting)}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  setFieldValue,
                  setSubmitting,
                  isSubmitting,
                }) => {
                  console.log("errors", errors);
                  const onHandleBlur = (e) => {
                    if (!values.recaptcha) {
                      reCaptchaRef.current.execute();
                      setSubmitting(true);
                    }
                    handleBlur(e);
                  };
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="common-form-row two-col-row justify-content-space-between d-flex">
                        <div className="common-form-cell">
                          <label htmlFor="">First Name</label>
                          <div className="input-box">
                            <Input
                              type="text"
                              name="first-name"
                              onBlur={onHandleBlur}
                              onChange={handleChange}
                              value={values["first-name"]}
                            />
                            {errors["first-name"] && <div className="form-error-msg">{errors["first-name"]}</div>}
                          </div>
                        </div>
                        <div className="common-form-cell">
                          <label htmlFor="">Last Name</label>
                          <div className="input-box">
                            <Input
                              type="text"
                              name="last-name"
                              onBlur={onHandleBlur}
                              onChange={handleChange}
                              value={values["last-name"]}
                            />
                            {errors["last-name"] && <div className="form-error-msg">{errors["last-name"]}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="common-form-row">
                        <div className="common-form-cell">
                          <label htmlFor="UserId" className="m-b-5">
                            Email Address
                          </label>
                          <div className="input-with-icon">
                            <Input
                              type="text"
                              name="your-email"
                              onChange={handleChange}
                              onBlur={onHandleBlur}
                              value={values["your-email"]}
                              id="UserId"
                              placeholder="Enter Your Email Id"
                            />
                            <div className="input-icon user-icon"></div>
                          </div>
                          {errors["your-email"] && <div className="form-error-msg">{errors["your-email"]}</div>}
                        </div>
                      </div>
                      <div className="common-form-row">
                        <div className="common-form-cell">
                          <label htmlFor="">Cell Phone</label>
                          <div className="input-box">
                            <Input
                              type="text"
                              name="cellphone"
                              onBlur={onHandleBlur}
                              onChange={handleChange}
                              value={values["cellphone"]}
                            />
                            {errors["cellphone"] && <div className="form-error-msg">{errors["cellphone"]}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="common-form-row">
                        <div className="common-form-cell">
                          <label htmlFor="">Country</label>
                          <div className="input-box">
                            <div className="input-box-select">
                              <select
                                name="country"
                                id="country"
                                value={values["country"]}
                                placeholder="Please select country"
                                onChange={(e) => {
                                  handleChange(e);
                                  getState(e?.target.value);
                                }}
                              >
                                <option></option>
                                {countryList?.map((country, index) => (
                                  <option value={country?.id} key={index}>
                                    {country?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="common-form-row">
                        <div className="common-form-cell">
                          <label htmlFor="">State</label>
                          <div className="input-box">
                            <div className="input-box-select">
                              <select
                                name="state"
                                id="state"
                                value={values["state"]}
                                onChange={(e) => {
                                  getCity(e.target.value);
                                  handleChange(e);
                                }}
                              >
                                <option></option>
                                {stateList?.map((state, index) => (
                                  <option value={state?.id} key={index}>
                                    {state?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="common-form-row">
                        <div className="common-form-cell">
                          <label htmlFor="">City</label>
                          <div className="input-box">
                            <div className="input-box-select">
                              <select name="City" id="City" value={values["City"]} onChange={handleChange}>
                                <option></option>
                                {cityList?.map((city, index) => (
                                  <option value={city?.name} key={index}>
                                    {city?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="common-form-row">
                        <div className="common-form-cell">
                          <label htmlFor="">Preferred communication method</label>
                          <div className="input-box">
                            <div className="input-box-select">
                              <select
                                name="comm-method"
                                value={values["comm-method"]}
                                id="country"
                                onChange={handleChange}
                              >
                                <option value="email">Email</option>
                                <option value="phone">Phone</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {values["comm-method"] == "phone" ? (
                        <div className="common-form-row">
                          <div className="common-form-cell">
                            <label htmlFor="">Preferred call time</label>
                            <div className="input-box">
                              <DatePicker
                                selected={values["call-time"]}
                                onChange={(e) => setFieldValue("call-time", e)}
                                minDate={moment().toDate()}
                                excludeOutOfBoundsTimes
                                showTimeSelect
                                name="call-time"
                                filterTime={(date) => {
                                  const isPastTime = new Date().getTime() > date.getTime();
                                  return !isPastTime;
                                }}
                                dateFormat="MMMM d, yyyy h:mm aa"
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="common-form-row">
                        <div className="common-form-cell">
                          <label htmlFor="">I am having an issue with:</label>
                          <div className="checkbox-wrapper">
                            <div className="custom-checkbox">
                              <input
                                type="checkbox"
                                checked={values["signing-up"].length ? true : false}
                                onChange={handleChange}
                                name="signing-up"
                                id="signingup"
                              />
                              <label htmlFor="signingup">
                                <span>Signing up</span>
                              </label>
                            </div>
                            <div className="custom-checkbox">
                              <input
                                type="checkbox"
                                checked={values["signing-in"].length ? true : false}
                                onChange={handleChange}
                                name="signing-in"
                                id="signingin"
                              />
                              <label htmlFor="signingin">
                                <span>Signing in</span>
                              </label>
                            </div>
                            <div className="custom-checkbox">
                              <input
                                type="checkbox"
                                checked={values["my-account-profile"].length ? true : false}
                                onChange={handleChange}
                                name="my-account-profile"
                                id="my-account-profile"
                              />
                              <label htmlFor="my-account-profile">
                                <span>My Account Profile</span>
                              </label>
                            </div>
                            <div className="custom-checkbox">
                              <input
                                type="checkbox"
                                checked={values["my-dashboard"].length ? true : false}
                                onChange={handleChange}
                                name="my-dashboard"
                                id="my-dashboard"
                              />
                              <label htmlFor="my-dashboard">
                                <span>My Dashboard</span>
                              </label>
                            </div>
                            <div className="custom-checkbox">
                              <input
                                type="checkbox"
                                checked={values["accessing-my-content"].length ? true : false}
                                onChange={handleChange}
                                name="accessing-my-content"
                                id="accessing-my-content"
                              />
                              <label htmlFor="accessing-my-content">
                                <span>Accessing my content</span>
                              </label>
                            </div>
                            <div className="custom-checkbox">
                              <input
                                type="checkbox"
                                onChange={handleChange}
                                checked={values["telescience-sessions"].length ? true : false}
                                name="telescience-sessions"
                                id="telescience-sessions"
                              />
                              <label htmlFor="telescience-sessions">
                                <span>Telescientist Sessions</span>
                              </label>
                            </div>
                            <div className="custom-checkbox">
                              <input
                                type="checkbox"
                                checked={values["something-else"].length ? true : false}
                                onChange={(e) => {
                                  setSomethingElse(e.target.checked);
                                  handleChange(e);
                                }}
                                name="something-else"
                                id="something-else"
                              />
                              <label htmlFor="something-else">
                                <span>Something else</span>
                              </label>
                            </div>
                          </div>
                          {isIssueNotSelected && <div className="form-error-msg">Please select any issue</div>}
                        </div>
                      </div>
                      {somethingElse ? (
                        <div className="common-form-row">
                          <div className="common-form-cell">
                            <label htmlFor="">Please specify </label>
                            <div className="input-box">
                              <Input
                                type="text"
                                name="something-else-text"
                                onBlur={onHandleBlur}
                                onChange={handleChange}
                                value={values["something-else-text"]}
                              />
                              {errors["something-else-text"] && (
                                <div className="form-error-msg">{errors["something-else-text"]}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="common-form-row d-flex">
                        <div className="common-form-cell">
                          <label htmlFor="UserId" className="m-b-5">
                            Comment
                          </label>
                          <div className="input-box">
                            <textarea
                              className="contact-textarea"
                              onChange={handleChange}
                              onBlur={onHandleBlur}
                              value={values["your-message"]}
                              name="your-message"
                              id=""
                              cols="30"
                              rows="5"
                            ></textarea>
                            {errors["your-message"] && <div className="form-error-msg">{errors["your-message"]}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="contact-form-cta d-flex align-center ">
                        <ReCAPTCHA
                          size="invisible"
                          ref={reCaptchaRef}
                          sitekey={recaptchaKey?.sitekey}
                          onChange={(value) => {
                            setFieldValue("recaptcha", value);
                            setSubmitting(false);
                          }}
                        />
                        <Button className="common-btn" type="submit" disabled={isSubmitting}>
                          Submit
                        </Button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="contact-us-address">
              <img src={helpCenterData?.acf?.help_center_image?.url ?? ""} />
            </div>
          </div>
        </div>
      </section>

      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
      />
    </div>
  );
};

export default HelpCenter;
