export const newsLetterApi = {
  username: "27d504dc0b5ae7c53bb17ae88dd3dfb2f9c411fa",
  password: "ae4e4ba9a41d24f1156630dc4152e539b19258b2",
};

export const recaptchaKey = {
  sitekey: "6LfxurwpAAAAAM3DNufJKTxHFAc--O5I3p0I--uf",
};

export const DiseaseType = ["All", "I dont know", "Other", "I'm Not Sure"];

export const textCharacterLimit = 100;
