import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const TelescienceSection = ({ content }) => {
  return (
    <section className="what-is-telescience">
      <div className="container">
        <h3 className="section-heading">{content?.telescience_section_title}</h3>
        {content?.telescience_section_content?.map((data, index) =>
          data?.image_position == "right" ? (
            <div className="what-is-telescience-row row1 animatedParent" key={index}>
              <ScrollAnimation
                duration={2}
                animateOnce
                animateIn="animate__fadeInLeft"
                className="what-is-telescience-content"
              >
                <p className="telescience-title">{data?.title}</p>
                <p className="telescience-content" dangerouslySetInnerHTML={{ __html: data?.content }} />
              </ScrollAnimation>
              <ScrollAnimation
                duration={2}
                animateOnce
                animateIn="animate__fadeInRight"
                className="telescience-img-box"
              >
                <img src={data?.image} alt="" />
              </ScrollAnimation>
            </div>
          ) : data?.image_position == "left" ? (
            <div className="what-is-telescience-row row2 animatedParent" key={index}>
              <ScrollAnimation
                duration={2}
                animateOnce
                animateIn="animate__fadeInRight"
                className="what-is-telescience-content"
              >
                <p className="telescience-title">{data?.title}</p>
                <p className="telescience-content" dangerouslySetInnerHTML={{ __html: data?.content }} />
                <img src={data?.icon} alt="" className="telescience-icon" />
              </ScrollAnimation>
              <ScrollAnimation
                duration={2}
                animateOnce
                animateIn="animate__fadeInLeft"
                className="telescience-img-box ml-auto"
              >
                <img src={data?.image} alt="" />
              </ScrollAnimation>
            </div>
          ) : null
        )}
      </div>
    </section>
  );
};
export default TelescienceSection;
