let HOST = ["synapticalhealth.org", "www.synapticalhealth.org"].includes(window?.location?.hostname)
  ? "https://cms.synapticalhealth.org/wp-json/"
  : "https://development-synapticalhealth-wp.demolinks.xyz/wp-json/";
let BETA_USER_REGISTRATION_FORM_WP_ID = ["synapticalhealth.org", "www.synapticalhealth.org"].includes(window?.location?.hostname)
  ? 1083
  : 1074
export const api = {
  HEADER_FOOTER: HOST + "synapticalhealth/v1/general",
  HOME_PAGE: HOST + "wp/v2/pages?slug=home",
  PRIVACY_POLICY_PAGE: HOST + "wp/v2/pages?slug=privacy-policy",
  TERMS_OF_USE_PAGE: HOST + "wp/v2/pages?slug=terms-of-use",
  HELP_CENTER_PAGE: HOST + "wp/v2/pages?slug=help-center",
  PROFESSIONAL_PAGE: HOST + "wp/v2/pages?slug=professional",
  WEBINARS_PAGE: HOST + "wp/v2/pages?slug=webinars",
  FAQS_PAGE: HOST + "wp/v2/pages?slug=faqs",
  PLAN_PRICING_PAGE: HOST + "wp/v2/pages?slug=plan-pricing",
  CAREERS_PAGE: HOST + "wp/v2/pages?slug=careers",
  OUR_TEAM_PAGE: HOST + "wp/v2/pages?slug=our-team",
  OUR_SERVICE_PAGE: HOST + "wp/v2/pages?slug=our-services",
  BREAST_CANCER_PAGE: HOST + "wp/v2/pages?slug=breast-cancer",
  FIND_A_RELE_SCIENTIST_PAGE: HOST + "wp/v2/pages?slug=find-a-tele-scientist",
  CONTACT_US_PAGE: HOST + "wp/v2/pages?slug=contact-us",
  ABOUT_US_PAGE: HOST + "wp/v2/pages?slug=about-us",
  DISEASE_LIST: HOST + "synapticalhealth/v1/disease/list",
  CONTACT_FORM_SUBMIT: (id) => HOST + `contact-form-7/v1/contact-forms/${id}/feedback`,
  HELP_CENTER_FORM: HOST + "contact-form-7/v1/contact-forms/431/feedback",
  NEWS_LETTER: HOST + "newsletter/v2/subscribers",
  TELESCIENTIST: HOST + "synapticalhealth/v1/telescientist/list",
  OUR_TEAM_LIST: HOST + "wp/v2/team/?_embed&orderby=date&order=asc",
  COUNTRY_LIST: HOST + "synapticalhealth/v1/country/list",
  STATE_LIST: HOST + "synapticalhealth/v1/state/list",
  CITY_LIST: HOST + "synapticalhealth/v1/city/list",
  JOB_FORM: HOST + "contact-form-7/v1/contact-forms/497/feedback",
  JOB_OPENING: HOST + "wp/v2/job_openings/?_embed&orderby=date&order=asc",
  PLAN_PRICING: HOST + "synapticalhealth/v1/plans",
  OUR_MISSION_VISION_AND_VALUE: HOST + "wp/v2/pages?slug=our-mission-vision-and-values",
  STRATAGIC_PARTNER: HOST + "wp/v2/pages?slug=strategic-partners",
  PRESS_NEWS: HOST + "wp/v2/pages?slug=press-news",
  HELP: HOST + "wp/v2/pages?slug=help",
  TEAM_SCIENCE: HOST + "wp/v2/pages?slug=team-science",
  EULA: HOST + "wp/v2/pages?slug=eula",
  BETA_USER_REGISTRATION_FORM_PAGE: HOST + "wp/v2/pages?slug=beta-user-registration-form",
  BETA_USER_REGISTRATION_FORM: HOST + "contact-form-7/v1/contact-forms/" + BETA_USER_REGISTRATION_FORM_WP_ID + "/feedback",
};

export default api;
