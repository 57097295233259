import React from "react";

const Modal = ({ visible, content, setVisible, width }) => {
  const divStyle = {
    display: visible ? "block" : "none",
    trasition: visible ? "all 1s" : "all 1s",
  };
  return (
    <div className="modal" style={divStyle}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()} style={{ width: width ? width : "1170px" }}>
        <div className="s-modal-header">
          <div className="close-s-modal-icon" onClick={() => setVisible(false)}>
            <img src="assets/images/icons/close-icon.svg" />
          </div>
        </div>

        <div className="modal-flex">{content}</div>
      </div>
    </div>
  );
};

export default Modal;
