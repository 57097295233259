import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api/Api";
import useApiRequest from "../../api/ApiRequest";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Modal from "../../components/Modal";

const OurTeam = () => {
  const request = useApiRequest();
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [ourTeamList, setOurTeamList] = useState([]);
  const [visibleProfile, setVisibleProfile] = useState(false);
  const [selectedMember, setSelectedMember] = useState();

  useEffect(() => {
    request({
      method: "get",
      url: api.HEADER_FOOTER,
    })?.then((response) => {
      setHeaderFooterData(response);
    });

    // Our Team List
    request({
      method: "get",
      url: api.OUR_TEAM_LIST,
    })?.then((response) => {
      setOurTeamList(response);
    });
  }, []);

  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <section className="p-t-50 header-space">
        <div className="container">
          <h1 className="section-heading">Our Team</h1>
          <ul className="team-list d-flex justify-content-center flex-wrap">
            {ourTeamList &&
              ourTeamList.map((team, index) => (
                <li key={index}>
                  <div className="team-card">
                    <div className="team-member-pic">
                      <img
                        onClick={() => {
                          setVisibleProfile(true);
                          setSelectedMember(team);
                        }}
                        src={team?._embedded?.["wp:featuredmedia"]?.[0]?.media_details?.sizes?.thumbnail?.s3?.url}
                      />
                    </div>
                    <div className="team-content d-flex direction-columan align-center justify-content-center">
                      <h3
                        onClick={() => {
                          setVisibleProfile(true);
                          setSelectedMember(team);
                        }}
                      >
                        {team?.title?.rendered}
                      </h3>
                      <p>{team?.acf?.designation}</p>
                      <div className="team-member-readMore">
                        <Link
                          to="#"
                          onClick={() => {
                            setVisibleProfile(true);
                            setSelectedMember(team);
                          }}
                        >
                          View more
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </section>
      <Modal
        visible={visibleProfile}
        setVisible={setVisibleProfile}
        width="1170px"
        content={
          <div className="s-modal-body">
            <div className="team-member-detail-box d-flex">
              <div className="team-member-detail-pic">
                <img src={selectedMember?._embedded["wp:featuredmedia"][0]?.source_url} alt="" />
              </div>
              <div className="team-member-detail-content">
                <h5>{selectedMember?.title?.rendered}</h5>
                <h6>{selectedMember?.acf?.designation}</h6>
                <p dangerouslySetInnerHTML={{ __html: selectedMember?.content?.rendered }} />
              </div>
            </div>
          </div>
        }
      />
      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
      />
    </div>
  );
};

export default OurTeam;
