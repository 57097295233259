import React from "react";
import "./adobe-agreement-acceptance.css";

const AdobeAgreementAcceptance = () => {
    return (
        <div className="agreement-acceptance-page">
            <div className="header-container">
                <div className="pdf-icon"></div>
                <p>Adobe Agreement</p>
            </div>
            <div className="content">
                <div className="icon-header">
                    <div className="icon-circle"></div>
                </div>
                <h1>You are all set!</h1>
                <h3>We will send the final agreement to all parties.</h3>
            </div>
        </div>
    );
};

export default AdobeAgreementAcceptance;
