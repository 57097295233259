import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import api from "../../api/Api";
import useApiRequest from "../../api/ApiRequest";

const PageNotFound = () => {
    const request = useApiRequest();

    const [headerFooterData, setHeaderFooterData] = useState([]);
    useEffect(() => {
        request({
            method: "get",
            url: api.HEADER_FOOTER,
        })?.then((response) => {
            setHeaderFooterData(response);
        });
    }, []);
    return (
        <div>
            <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center'
            }}>
                <img src="assets/images/Group.svg" width={'200'} height={'200px'} style={{ marginTop: '120px' }} />
                <h1 style={{ margin: 0, fontWeight: 'bold' }}>Page Not Found</h1>
                <p style={{ marginTop: '20px', fontSize: '18px', fontWeight: 'bold' }}>THE PAGE YOU WERE LOOKING FOR DOES NOT EXIST.</p>
                <p style={{ marginTop: '10px', fontSize: '15px' }}>YOU MAY HAVE MISTYPED THE ADDRESS OR THE PAGE MAY HAVE MOVED.</p>
            </div>
            <Footer
                logo={headerFooterData?.site_logo?.url}
                leftContent={headerFooterData?.footer_left_side_content}
                copyRightText={headerFooterData?.footer_copyright_text}
                socialIcons={headerFooterData?.site_social_icons}
                copyRightMenu={headerFooterData?.footer_copyright_menu}
                footerMenu={headerFooterData?.footer_right_side_content?.menus}
                newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
                poweredBy={headerFooterData?.powered_by}
            />
        </div>
    );
};

export default PageNotFound;
