import React, { useRef, useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import * as yup from "yup";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../constant";
import useApiRequest from "../../api/ApiRequest";
import api from "../../api/Api";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/slice/ToastReducer";

const JobForm = ({ selectedJob, setJobFormVisible }) => {
  const [resumeFile, setResumeFile] = useState(null)
  const reCaptchaRef = useRef(null);
  const request = useApiRequest();
  const dispatch = useDispatch();

  const onFormSubmit = (values, resetForm, setSubmitting) => {
    const jobForm = new FormData();
    jobForm.append("job", selectedJob);
    jobForm.append("resume", resumeFile);
    Object.keys(values).forEach((key) => jobForm.append(key, values[key]));
    request({
      method: "post",
      url: api.JOB_FORM,
      data: jobForm,
    })?.then((response) => {
      resetForm();
      if (response?.invalid_fields.length == 0) {
        dispatch(showToast({ type: "success", message: response.message }));
        setSubmitting(false);
        setJobFormVisible(false);
      }
    });
  };

  return (
    <div className="s-modal-body career-form">
      <Formik
        initialValues={{ recaptcha: "", "first-name": "", "last-name": "", "your-email": "", "your-message": "", resume: null }}
        validationSchema={yup.object().shape({
          recaptcha: yup.string().required(),
          "first-name": yup.string().required("Please fill first name field."),
          "last-name": yup.string().required("Please fill last name field."),
          "your-email": yup.string().email("Must be a valid email").required("Please fill email field."),
          ...(!resumeFile ? { "resume": yup.mixed().required("Please upload your resume.") } : {})
        })}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { resetForm, setSubmitting }) => onFormSubmit(values, resetForm, setSubmitting)}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors, setFieldValue, setSubmitting, isSubmitting }) => {
          const onHandleBlur = (e) => {
            if (!values.recaptcha) {
              reCaptchaRef.current.execute();
              setSubmitting(true);
            }
            handleBlur(e);
          };
          return (
            <form onSubmit={handleSubmit}>
              <div className="common-form-row two-col-row justify-content-space-between d-flex">
                <div className="common-form-cell">
                  <label htmlFor="">First Name</label>
                  <div className="input-box">
                    <Input type="text" name="first-name" onBlur={onHandleBlur} onChange={handleChange} value={values["first-name"]} />
                    {errors["first-name"] && <div className="form-error-msg">{errors["first-name"]}</div>}
                  </div>
                </div>
                <div className="common-form-cell">
                  <label htmlFor="">Last Name</label>
                  <div className="input-box">
                    <Input type="text" name="last-name" onBlur={onHandleBlur} onChange={handleChange} value={values["last-name"]} />
                    {errors["last-name"] && <div className="form-error-msg">{errors["last-name"]}</div>}
                  </div>
                </div>
              </div>
              <div className="common-form-row">
                <div className="common-form-cell">
                  <label htmlFor="UserId" className="m-b-5">
                    Email Address
                  </label>
                  <div className="input-with-icon">
                    <Input
                      type="text"
                      name="your-email"
                      onChange={handleChange}
                      onBlur={onHandleBlur}
                      value={values["your-email"]}
                      id="UserId"
                      placeholder="Enter Your Email Id"
                    />
                    <div className="input-icon user-icon"></div>
                  </div>
                  {errors["your-email"] && <div className="form-error-msg">{errors["your-email"]}</div>}
                </div>
              </div>
              <div className="common-form-row d-flex">
                <div className="common-form-cell">
                  <label htmlFor="UserId" className="m-b-5">
                    Upload Your Resume
                  </label>
                  <div className="input-box">
                    <input
                      className="contact-textarea"
                      type="file"
                      onChange={(e) => setResumeFile(e.target.files[0])}
                      onBlur={onHandleBlur}
                      value={values["resume"]}
                      name="resume"
                    />
                    <div className="form-error-msg">{ }</div>
                  </div>
                  {errors["resume"] && <div className="form-error-msg">{errors["resume"]}</div>}
                </div>
              </div>
              <div className="common-form-row d-flex">
                <div className="common-form-cell">
                  <label htmlFor="UserId" className="m-b-5">
                    Comment
                  </label>
                  <div className="input-box">
                    <textarea
                      className="contact-textarea"
                      onChange={handleChange}
                      onBlur={onHandleBlur}
                      value={values["your-message"]}
                      name="your-message"
                      id=""
                      cols="30"
                      rows="5"
                    ></textarea>
                    <div className="form-error-msg">{ }</div>
                  </div>
                </div>
              </div>
              <div className="contact-form-cta d-flex align-center ">
                <ReCAPTCHA
                  size="invisible"
                  ref={reCaptchaRef}
                  sitekey={recaptchaKey?.sitekey}
                  onChange={(value) => {
                    setFieldValue("recaptcha", value);
                    setSubmitting(false);
                  }}
                />
                <div className="d-flex justify-content-end">
                  <Button className="common-btn" type="submit" disabled={isSubmitting}>
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default JobForm;
