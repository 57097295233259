import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import ScrollAnimation from "react-animate-on-scroll";
import { Element } from "react-scroll";
const HowItWorksSection = ({ content, is_beta, eleRef, noLinks }) => {
  const isBooleanRegistration = is_beta ?? false

  return (
    <Element name="/#how-it-work">
      <section className="home-how-it-work" id="how-it-work" ref={eleRef}>
        <div className="container d-flex flex-wrap">
          <h3 className="section-heading">{content?.how_it_works_title}</h3>
          <h4 className="section-sub-heading">{content?.how_it_works_subtitle}</h4>
          <div className="animatedParent">
            <div className="how-it-work-content">
              <ScrollAnimation duration={2} animateIn="animate__zoomIn" animateOnce>
                <ul>
                  {content?.how_it_works_steps?.map((steps, index) => (
                    <li key={index}>
                      <div className="home-how-it-work-icon">
                        <img src={steps?.icon} alt="" />
                      </div>
                      <div className="home-how-it-work-text">
                        <h5
                          className={noLinks && "removelink"}
                          onClick={isBooleanRegistration && Boolean(JSON.parse(isBooleanRegistration)) ? () => window.open('/beta-user-registration') : () => !noLinks && window.open(steps?.title?.url)}
                        >
                          {index + 1}. {steps?.title?.title}
                        </h5>
                        {steps?.content}
                      </div>
                    </li>
                  ))}
                </ul>
              </ScrollAnimation>
              {!noLinks && (
                <div className="home-how-it-work-cta">
                  <Button onClick={isBooleanRegistration && Boolean(JSON.parse(isBooleanRegistration)) ? () => window.open('/beta-user-registration') : () => window.open(content?.how_it_works_button_link?.url)}>
                    {content?.how_it_works_button_link?.title}
                  </Button>
                </div>
              )}
            </div>
            <ScrollAnimation
              animateIn="animate__fadeInRight"
              className="home-how-it-work-props"
              duration={2}
              animateOnce
              style={{
                background: `url(${content?.background_image ? content?.background_image : ""}) no-repeat bottom right`,
              }}
            ></ScrollAnimation>
          </div>
        </div>
      </section>
    </Element>
  );
};
export default HowItWorksSection;
