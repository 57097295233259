import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const ServicesSection = ({ content }) => {
  const isMobile = window.innerWidth <= 500;
  const freeServices = content?.services_list.filter((item) => item.monthly_membership_0);
  const paidServices = content?.services_list.filter((item) => item.monthly_membership_20);

  return isMobile ? (
    <section className="home-services-mobile">
      <div className="container">
        <h3>{content?.service_title}</h3>
        {freeServices?.length > 0 && (
          <div className="home-services-card">
            <div className="home-membership">
              <div>
                <span>{content?.price_labels?.currency}</span>
                {content?.price_labels?.membership_0_price}
              </div>
              <strong>{content?.price_labels?.monthly_membership_title}</strong>
            </div>
            <ul>
              {freeServices?.map((service, index) => (
                <li key={index}>{service?.service_title}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="home-services-card">
          <div className="home-membership">
            {/* <div>
              <span>{content?.price_labels?.currency}</span>
              {content?.price_labels?.membership_20_price}
            </div> */}
            <strong>{content?.price_labels?.monthly_membership_title}</strong>
          </div>
          <ul>
            {paidServices?.map((service, index) => (
              <li key={index}>{service?.service_title}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  ) : (
    <section className="home-services animatedParent">
      <div className="container">
        <div className="home-services-table">
          <ScrollAnimation duration={2} animateOnce animateIn="animate__zoomIn">
            <table border="0" cellSpacing="0" width="100%" className="animated zoomIn">
              <thead>
                <tr>
                  <th width="60%">
                    <h6>{content?.service_title}</h6>
                  </th>
                  <th width="20%" className="home-membership"></th>
                  {/* {freeServices?.length > 0 && (
                    <th width="20%" className="home-membership">
                      <div>
                        <span>{content?.price_labels?.currency}</span>
                        {content?.price_labels?.membership_0_price}
                      </div>
                      <strong>{content?.price_labels?.monthly_membership_title}</strong>
                    </th>
                  )} */}
                  {/* <th width="20%" className="home-membership">
                    <div>
                      <span>{content?.price_labels?.currency}</span>
                      {content?.price_labels?.membership_20_price}
                    </div>
                    <strong>{content?.price_labels?.monthly_membership_title}</strong>
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {content?.services_list?.map((service, index) => (
                  <tr key={index}>
                    <td>{service?.service_title}</td>
                    {/* {freeServices?.length > 0 && (
                      <td>
                        {service?.monthly_membership_0 ? (
                          <img src="assets/images/icons/Check.svg" alt="" />
                        ) : (
                          <img src="assets/images/icons/Close.svg" className="service-close-icon" alt="" />
                        )}
                      </td>
                    )} */}
                    <td>
                      {service?.monthly_membership_20 ? (
                        <img src="assets/images/icons/Check.svg" alt="" />
                      ) : (
                        <img src="assets/images/icons/Close.svg" className="service-close-icon" alt="" />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  {/* <td width="60%">
                    <span className="price">Price</span>
                  </td> */}
                  <td></td>
                  {/* {freeServices?.length > 0 && (
                    <td width="20%" className="home-membership">
                      <div>
                        <span>{content?.price_labels?.currency}</span>
                        {content?.price_labels?.membership_0_price}
                      </div>
                      <strong>{content?.price_labels?.membership_duration_text}</strong>
                    </td>
                  )} */}
                  {/* <td width="20%" className="home-membership">
                    <div>
                      <span>{content?.price_labels?.currency}</span>
                      {content?.price_labels?.membership_20_price}
                    </div>
                    <strong>{content?.price_labels?.membership_duration_text}</strong>
                  </td> */}
                </tr>
              </tfoot>
            </table>
          </ScrollAnimation>
        </div>
      </div>
    </section>
  );
};
export default ServicesSection;
