import React, { useEffect, useState } from "react";
import useApiRequest from "../../../api/ApiRequest";
import api from "../../../api/Api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Button from "../../../components/Button";
import { DiseaseType, textCharacterLimit } from "../../../constant";
import { characterLimit } from "../../../utils/utils";
import parse from "html-react-parser";

const OurTelescientists = ({ noLinks }) => {
  const request = useApiRequest();
  const [diseaseList, setDiseaseList] = useState([]);
  const [teleScientistList, setTeleScientistList] = useState([]);
  const [dropdownVisible, setdropdownVisible] = useState(false);
  const [active, setActive] = useState("Ductal Carcinoma In Situ (DCIS)");

  useEffect(() => {
    request({
      method: "get",
      url: api.DISEASE_LIST,
    })?.then((response) => {
      setDiseaseList(response?.data?.filter((item) => !DiseaseType.includes(item?.option)));
    });
    filterTelescintiest("Ductal Carcinoma In Situ (DCIS)");
  }, []);

  const filterTelescintiest = (disease_id) => {
    request({
      method: "post",
      url: api.TELESCIENTIST,
      data: { disease_id: disease_id },
    })?.then((response) => {
      setActive(disease_id);
      setTeleScientistList(response?.data);
    });
  };
  return (
    <section className="home-our-tele-scientists">
      <div className="container">
        <h3 className="section-heading">Our Telescientists</h3>
        <h4 className="section-sub-heading">Filter by Disease Type:</h4>
        <div className="telescientists-tab-with-slider d-flex">
          <div className="telescientists-tab-section">
            <div
              className="telescientists-dropdown"
              id="telescientists-dropdown"
              onClick={() => setdropdownVisible(!dropdownVisible)}
            >
              Choose Disease Type
            </div>
            <ul
              className="d-flex direction-columan"
              id="telescientists-dropdown-opt"
              style={{ display: dropdownVisible ? "block" : "" }}
            >
              {diseaseList?.map((disease, index) => (
                <li
                  key={index}
                  className={active == disease?.option ? "active-tab" : ""}
                  onClick={() => {
                    filterTelescintiest(disease?.option);
                    setdropdownVisible(false);
                  }}
                >
                  {disease?.option}
                </li>
              ))}
            </ul>
          </div>
          <div className="home-tele-scientists-slider">
            <div className="swiper teleScientists">
              <div className="swiper-wrapper">
                {teleScientistList?.length > 0 ? (
                  <Swiper
                    // autoplay={{
                    //   delay: 2500,
                    //   disableOnInteraction: false,
                    // }}
                    loop={false}
                    spaceBetween={10}
                    pagination={false}
                    slidesPerView={1}
                    modules={[Autoplay, Navigation, Pagination]}
                    navigation={true}
                  >
                    {teleScientistList?.map((scientist, index) => (
                      <SwiperSlide className="swiper-slide" key={index}>
                        <div className="d-flex">
                          <div className="hts-left">
                            <div className="home-tele-scientists-pic">
                              <img
                                src={
                                  scientist?.user_uuid?.profile_pic
                                    ? scientist?.user_uuid?.profile_pic
                                    : "assets/images/dummy.png"
                                }
                                alt=""
                              />
                            </div>
                            <div className="home-tele-scientists-name-exp">
                              <h5 className="home-tele-scientists-block-heading">
                                {scientist?.user_uuid?.full_name}
                                {scientist?.user_uuid?.highest_qualification
                                  ? ", " + scientist?.user_uuid?.highest_qualification
                                  : ""}
                              </h5>
                              <p>{scientist?.user_uuid?.sub_disease}</p>
                              <div className="home-tele-scientists-exp">
                                Work Experience: {scientist?.user_uuid?.total_experience} Years
                              </div>
                            </div>
                            {!noLinks && (
                              <div className="hts-book-now">
                                <Button
                                  size="large"
                                  onClick={() => window.open(scientist?.user_uuid?.book_session_link?.url)}
                                >
                                  {scientist?.user_uuid?.book_session_link?.title}
                                </Button>
                              </div>
                            )}
                          </div>
                          <div className="hts-right">
                            <div className="home-tele-scientists-slider-content">
                              <div className="home-tele-scientists-qualifications">
                                <ol>
                                  <li>
                                    <div className="home-qualifiction-title">Qualifications</div>
                                    <div
                                      className="ts-qualification"
                                      dangerouslySetInnerHTML={{
                                        __html: scientist?.user_uuid?.educational_details
                                          ? scientist?.user_uuid?.educational_details
                                          : "",
                                      }}
                                    />
                                  </li>
                                  <li>
                                    <div className="home-qualifiction-title">Experience</div>
                                    <div
                                      className="ts-qualification"
                                      dangerouslySetInnerHTML={{
                                        __html: scientist?.user_uuid?.experience_details
                                          ? scientist?.user_uuid?.experience_details
                                          : "",
                                      }}
                                    />
                                  </li>
                                  <li>
                                    <div className="home-qualifiction-title">Awards and Publications</div>
                                    <div className="award-links">
                                      {parse(
                                        scientist?.user_uuid?.publication_and_reward
                                          ? scientist?.user_uuid?.publication_and_reward
                                          : ""
                                      )}
                                    </div>
                                  </li>
                                </ol>
                              </div>
                              <div className="home-tele-scientists-ratings">
                                <div className="d-flex">
                                  <h5 className="home-tele-scientists-block-heading">Reviews</h5>
                                </div>
                                {scientist?.user_uuid?.review?.length > 0 ? (
                                  <ul className="tele-scientists-ratings-list">
                                    {scientist?.user_uuid?.review?.map((review, index) => (
                                      <li key={index}>
                                        <div className="tele-scientists-single-rating">
                                          <div className="user-name">{review?.review_by?.first_name}</div>
                                          <div>{parse(characterLimit(review?.review_comment, textCharacterLimit))}</div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  <div>No Reviews</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  <div className="not-found-msg">Not Available</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default OurTelescientists;
