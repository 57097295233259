import React, { useEffect, useState } from "react";
import api from "../../api/Api";
import useApiRequest from "../../api/ApiRequest";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const EulaPage = () => {
  const request = useApiRequest();
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [termsOfUseData, setTermsOfUseData] = useState([]);

  useEffect(() => {
    request({
      method: "get",
      url: api.HEADER_FOOTER,
    })?.then((response) => {
      setHeaderFooterData(response);
    });
    request({
      method: "get",
      url: api.EULA,
    })?.then((response) => {
      setTermsOfUseData(response[0]);
    });
  }, []);

  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <section className="static-page header-space m-b-50">
        <div className="container">
          <h1 className="section-heading">{termsOfUseData?.title?.rendered}</h1>
          <div dangerouslySetInnerHTML={{ __html: termsOfUseData?.content?.rendered }}></div>
        </div>
      </section>
      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
        // noLinks={true}
      />
    </div>
  );
};

export default EulaPage;
