import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const PartnershipSection = ({ content }) => {
  const isMobile = window.innerWidth <= 500;
  return (
    <section className="home-Partnership">
      <div className="container">
        <h3 className="section-heading">{content?.title}</h3>
      </div>
      <div className="home-partnership-slider-container">
        <div className="swiper" id="home-partnership-slider">
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            spaceBetween={10}
            speed={isMobile ? 1000 : 500}
            pagination={{ clickable: true }}
            breakpoints={{
              200: {
                slidesPerView: 1,
              },
              500: {
                slidesPerView: 2,
              },
              767: {
                slidesPerView: 5,
              },
            }}
            slidesPerView={5}
            modules={[Autoplay, Navigation, Pagination]}
            navigation={true}
          >
            {content?.logos?.map((logo, index) => (
              <SwiperSlide className="swiper-slide" key={index}>
                <img src={logo} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="slider_powered_by_text">
        <p className="slider_bottom_text">
            {content?.powered_by?.powered_by_text}
          <a href={content?.powered_by?.url} target="_blank">{content?.powered_by?.url_text}</a>
        </p>
      </div>
    </section>
  );
};
export default PartnershipSection;
