import React, { useState } from "react";

const Accordion = ({ title, content, onToggle, active }) => {
  return (
    <div className="single-faq">
      <div className={` faq-question accordion_item ${active ? "active" : ""}`}>
        <div className="faq-que-wrapper" onClick={onToggle}>
          <h4>{title}</h4>
          {active ? (
            <img src="assets/images/icons/ArrowUp.svg" className="accordion-arrow" />
          ) : (
            <img src="assets/images/icons/ArrowDown.svg" className="accordion-arrow" />
          )}
        </div>
      </div>
      <div className={`faq-ans answer_wrapper ${active ? "open" : ""}`}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default Accordion;
