import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import TextComponent from "../../components/TextComponent";
const TsDetailsDrawer = ({ setOpenDetails, openDetails, tsData, setSelectedTs, headerFooterData, setHeaderFooterData }) => {

  let path = window?.location?.pathname?.replace("/", "");

  return (
    <>
      <div className={`ts-detials-container ${openDetails ? "show-ts-profile-details" : ""}`}>
        <div className="close-profile" onClick={() => setOpenDetails(false)}>
          <img src="/assets/images/icons/Close.svg" alt="" />
        </div>
        <div className="ts-detial-body">
          <div className="ts-short-profile d-flex">
            <img
              src={tsData?.user_uuid?.profile_pic}
              alt=""
              style={{ width: "100px", height: "100%", borderRadius: "50px", aspectRatio: "1/1" }}
            />
            <div className=" ts-sort-details ts-short-profile-content">
              <h4 className="ts-name">
                {tsData?.user_uuid?.full_name ?? ""}
                {tsData?.user_uuid?.highest_qualification ? ", " + tsData?.user_uuid?.highest_qualification : ""}
              </h4>
              {/* <h3>{showtsDetails?.disease[0]?.disease_name ?? "No Disease Assigned"} </h3> */}
              {tsData?.user_uuid?.total_experience ? (
                <p>
                  Work Experience: <span className="ts-exp">{tsData?.user_uuid?.total_experience} Years</span>
                </p>
              ) : null}

              <h6 className="m-b-10 m-t-10 fw-600 fs-12">{parse(tsData?.user_uuid?.current_designation ?? "")}</h6>
            </div>
          </div>
          {tsData?.user_uuid?.educational_details && (
            <div className="ts-profile-qualifications m-t-15">
              <h6 className="ts-profile-title">Qualifications</h6>
              <div className="ts-profile-qf-list m-t-15 p-b-15 p-b-15">
                <TextComponent text={tsData?.user_uuid?.educational_details ?? ""} isHtml={true} />
              </div>
            </div>
          )}

          {tsData?.user_uuid?.experience_details && (
            <div className="ts-profile-qualifications m-t-15">
              <h6 className="ts-profile-title">Experience</h6>
              <div className="ts-profile-qf-list m-t-15 p-b-15">
                {/* <div className="ts-profile-sub-title">Experience Details</div> */}
                <TextComponent text={tsData?.user_uuid?.experience_details ?? ""} isHtml={true} />
              </div>
            </div>
          )}

          {tsData?.user_uuid?.publication_and_reward ? (
            <div className="ts-profile-qualifications m-t-15">
              <h6 className="ts-profile-title">Awards and Publications</h6>
              <div className="ts-profile-qf-list m-t-15 p-b-15">
                <TextComponent text={tsData?.user_uuid?.publication_and_reward ?? ""} isHtml={true} />
              </div>
            </div>
          ) : null}

          {tsData?.user_uuid?.additional_description ? (
            <div className="ts-profile-qualifications m-t-15">
              <h6 className="ts-profile-title">Description</h6>
              <div className="ts-profile-qf-list m-t-15 p-b-15">
                <TextComponent text={tsData?.user_uuid?.additional_description ?? ""} isHtml={true} />
              </div>
            </div>
          ) : null}

          <div className="ts-profile-review m-t-15">
            <h6 className="ts-profile-title">Reviews</h6>
            {tsData?.user_uuid?.review.length > 0 ? (
              <ul className="m-to-15">
                {tsData?.user_uuid?.review?.map((review, index) => (
                  <li key={index}>
                    <div className="ts-profile-sub-title">{review?.review_by?.first_name}</div>
                    <div className="review-content">
                      <p className={review?.readMore ? "full-review" : "short-review"}>
                        {review?.readMore ? parse(review?.review_comment) : parse(review?.review_comment.slice(0, 100))}
                      </p>
                      {review?.review_comment?.length > 100 && (
                        <div className="show-full-review text-right">
                          {review?.readMore ? (
                            <span
                              onClick={() => {
                                let newTsData = { ...tsData };
                                newTsData.user_uuid.review[index].readMore = false;
                                setSelectedTs(newTsData);
                              }}
                            >
                              Less
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                let newTsData = { ...tsData };
                                newTsData.user_uuid.review[index].readMore = true;
                                setSelectedTs(newTsData);
                              }}
                            >
                              Read More
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div>No Reviews</div>
            )}
          </div>
        </div>

        <div className="ts-profile-cta">
          {path != "beta-user-registration"
            ?
            <button onClick={() => window.open(tsData?.user_uuid?.book_session_link?.url)} className="common-btn">Check Availability</button>
            : null}
        </div>
      </div >
      <div
        className={`ts-detail-overlay ${openDetails ? "show-ts-profile-details" : ""}`}
        onClick={() => setOpenDetails(false)}
      ></div>
    </>
  );
};

export default TsDetailsDrawer;
