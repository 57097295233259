import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Button from "./Button";
import api from "../api/Api";
import useApiRequest from "../api/ApiRequest";
const Header = ({ logo = "assets/images/logo.svg", content, noLinks = false }) => {
  const request = useApiRequest();
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  let path = window?.location?.pathname?.replace("/", "");

  const isBooleanRegistration = content?.beta_registration ?? false

  return (
    <header id="header" className="headerBg">
      <div className="container-fluid">
        <div className="header-wraper d-flex align-center">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="Synaptical Health" />
            </Link>
          </div>

          <div
            className={`header-menu-trigger ${menuOpen ? "menu-open" : ""}`}
            id="header-menu-trigger"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={`header-right d-flex align-center ${menuOpen ? "menu-open" : ""}`} id="header-right">
            <div className="top-menu ">
              {!noLinks ? (
                <ul className="d-flex justify-content-center">
                  {content?.header_menu?.map((menu, index) => {
                    return (
                      <li key={index} className="mega-drop-down">
                        {menu.post_name == "how-it-works" && window?.location?.pathname == "/" ? (
                          <ScrollLink to={menu?.url} smooth={true} onClick={() => setMenuOpen(!menuOpen)}>
                            {menu?.title}
                          </ScrollLink>
                        ) : (
                          <Link to={menu?.url} target={menu?.target}>
                            {menu?.title}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div className="launch-soon text-center header-label">Launching Soon</div>
              )}
            </div>
            {/* Megamenu */}
            {/* {menu?.submenu && <img src="assets/images/icons/ArrowDown.svg" />}
                      {menu?.submenu && (
                        <div className="mega-menu">
                          <div className="menu-wrapper">
                            <div className="menu-items">
                              {menu?.submenu?.length > 0 &&
                                menu?.submenu?.map((sMenu, sIndex) => (
                                  <li className="menu-label" key={sIndex} onClick={() => setSelectedMenu(sIndex)}>
                                    <div className="menu-content">{sMenu?.menu_label}</div>

                                    <ul className="mega-menu-open">
                                      {sMenu?.menu_items?.map((subMenu, SubIndex) => (
                                        <li key={SubIndex}>{subMenu?.title}</li>
                                      ))}
                                    </ul>
                                  </li>
                                ))}
                            </div>
                          </div>
                        </div>
                      )} */}
            {!noLinks && (
              <div className="signin-signup-container ml-auto d-flex align-center">
                <div className="header-right-links">
                  {path != "beta-user-registration"
                    ?
                    <Button btntype="link" onClick={() => window.open(content?.header_sign_in_button_title?.url)}>
                      {content?.header_sign_in_button_title?.title}
                    </Button>
                    :
                    <Button style={{ color: "white" }} onClick={() => window.open(content?.header_sign_in_button_title?.url)}>
                      {content?.header_sign_in_button_title?.title}
                    </Button>
                  }
                  {path != "beta-user-registration"
                    ?
                    <Button size="small" onClick={isBooleanRegistration && Boolean(JSON.parse(isBooleanRegistration)) ? () => window.open('/beta-user-registration') : () => window.open(content?.header_sign_up_button_title?.url)}>
                      {content?.header_sign_up_button_title?.title}
                    </Button>
                    :
                    null
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
