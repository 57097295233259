import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Popup from "../components/Popup";
import AboutUs from "../pages/aboutus";
import BreastCancer from "../pages/breast-cancer";
import Career from "../pages/career";
import ComingSoon from "../pages/coming-soon";
import ContactUs from "../pages/contact-us";
import Faq from "../pages/faq";
import FindTeleScientist from "../pages/find-tele-scientist";
import Help from "../pages/help";
import HelpCenter from "../pages/help-center";
import Home from "../pages/home";
import OurMission from "../pages/our-mission-vision-and-values";
import OurServices from "../pages/our-services";
import OurTeam from "../pages/our-team";
import PlanAndPricing from "../pages/plan-pricing";
import PressNews from "../pages/press-news";
import PrivacyPolicy from "../pages/privacy-policy";
import Professional from "../pages/professional";
import StrategicPartners from "../pages/strategic-partners";
import TeamScience from "../pages/team-science";
import TermOfUse from "../pages/terms-of-use";
import Webinars from "../pages/webinars";
import ScrollToTop from "../utils/ScrollToTop";
import EulaPage from "../pages/eula";
import LaunchHome from "../pages/home/indexCopy";
import BetaUserRegistration from "../pages/beta-user-registration";
import PageNotFound from "../pages/pagenotfound";
import AdobeAgreementAcceptance from "../pages/adobe-agreement-acceptance";

const Routers = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<LaunchHome />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/find-a-tele-scientist" element={<FindTeleScientist />} />
        <Route path="/breast-cancer" element={<BreastCancer />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-services" element={<OurServices />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/plan-pricing" element={<PlanAndPricing />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/webinars" element={<Webinars />} />
        <Route path="/professional" element={<Professional />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermOfUse />} />
        <Route path="/our-mission-vision-and-values" element={<OurMission />} />
        <Route path="/strategic-partners" element={<StrategicPartners />} />
        <Route path="/press-news" element={<PressNews />} />
        <Route path="/help" element={<Help />} />
        <Route path="/team-science" element={<TeamScience />} />
        <Route path="/eula" element={<EulaPage />} />
        <Route path="/beta-user-registration" element={<BetaUserRegistration />} />
        <Route path="/adobe-agreement-acceptance" element={<AdobeAgreementAcceptance />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Popup />
    </Router>
  );
};
export default Routers;
