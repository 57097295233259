import React from "react";

const Input = ({ icon = null, ...props }) => {
  return (
    <div className="d-flex justify-content-space align-center  w-100">
      <input type="text" {...props} />
      {icon && <div>{icon}</div>}
    </div>
  );
};
export default Input;
