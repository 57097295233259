import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import api from "../../api/Api";
import useApiRequest from "../../api/ApiRequest";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const AboutUs = () => {
  const request = useApiRequest();
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [aboutUsData, setAboutUsData] = useState([]);
  useEffect(() => {
    request({
      method: "get",
      url: api.HEADER_FOOTER,
    })?.then((response) => {
      setHeaderFooterData(response);
    });

    request({
      method: "get",
      url: api.ABOUT_US_PAGE,
    })?.then((response) => {
      setAboutUsData(response[0]);
      console.log(aboutUsData, "--")
    });
  }, []);

  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <section className=" header-space ">
        <div className="page-content-wrapper">
          {aboutUsData?.acf?.about_us_section?.render_this_section === "Yes" ? (
            <div className="container">
              <div className="page-title">
                <h1 className="section-heading">{aboutUsData?.acf?.about_us_section?.title}</h1>
                <p className="section-sub-title">{aboutUsData?.acf?.about_us_section?.sub_title}</p>
              </div>
              <div className="hero-image">
                <ScrollAnimation duration={2} animateOnce animateIn="animate__fadeIn">
                  <img src={aboutUsData?.acf?.about_us_section?.about_us_image} />
                </ScrollAnimation>
              </div>
              <ScrollAnimation duration={2} animateOnce animateIn="animate__fadeInLeft">
                <div
                  className="about-page-content"
                  dangerouslySetInnerHTML={{ __html: aboutUsData?.acf?.about_us_section?.about_us_content }}
                />
              </ScrollAnimation>
            </div>) 
          : null}
          {aboutUsData?.acf?.leadership_team_section?.render_leadership_section === "Yes" ? (
            <div className="about-us-content-wrapper">
              <div className="container">
                <div className="leadership-team-section">
                  <h1 className="section-heading">{aboutUsData?.acf?.leadership_team_section?.title}</h1>
                  <ul className="leadership-list d-flex justify-content-center flex-wrap">

                    {aboutUsData?.acf?.leadership_team_section?.team_info &&
                      aboutUsData?.acf?.leadership_team_section?.team_info?.map((team, index) => (
                        <li key={index}>
                          <div className="about-team-card">
                            <div className="about-team-member-pic">
                              <img src={team?.image} />
                            </div>
                            <div className="about-team-content d-flex direction-columan align-center justify-content-center">
                              <h3>{team?.name}</h3>
                              <p>{team?.designation}</p>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>)
          : null}
          {aboutUsData?.acf?.board_of_director_section?.render_bod_section === "Yes" ? (
            <div className="about-us-board">            
              <div className="container">
                <h1 className="section-heading">{aboutUsData?.acf?.board_of_director_section?.title}</h1>
                <div className="board-list">
                  {aboutUsData?.acf?.board_of_director_section?.board_of_directors &&
                    aboutUsData?.acf?.board_of_director_section?.board_of_directors?.map((team, index) => (
                      <div className="board-card" key={index}>
                        <div className="board-image">
                          <img src={team?.image} alt="" />
                        </div>
                        <div className="board-name">{team?.name}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>) 
          : null}
        </div>
      </section>
      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
      />
    </div>
  );
};

export default AboutUs;
