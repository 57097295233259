import axios from "axios";

const useApiRequest = () => {
  const request = (apiParams) => {
    return axios
      .request(apiParams)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        console.log("Api error =>", err);
        return err;
      });
  };

  return request;
};

export default useApiRequest;
