import React, { useEffect, useState } from "react";
import api from "../../api/Api";
import useApiRequest from "../../api/ApiRequest";
import Accordion from "../../components/Accordion";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Faq = () => {
  const request = useApiRequest();
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [clicked, setClicked] = useState("0");

  useEffect(() => {
    request({
      method: "get",
      url: api.HEADER_FOOTER,
    })?.then((response) => {
      setHeaderFooterData(response);
    });

    request({
      method: "get",
      url: api.FAQS_PAGE,
    })?.then((response) => {
      setFaqData(response[0]);
    });
  }, []);

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <section className="header-space m-b-50 p-t-50">
        <div className="container">
          <h1 className="section-heading">{faqData?.title?.rendered}</h1>
          <div dangerouslySetInnerHTML={{ __html: faqData?.content?.rendered }}></div>
          <div className="faq-container m-t-30">
            {faqData?.acf?.faqs &&
              faqData?.acf?.faqs?.map((item, index) => (
                <Accordion
                  active={clicked === index}
                  onToggle={() => handleToggle(index)}
                  key={index}
                  title={item?.question}
                  content={item?.answer}
                />
              ))}
          </div>
        </div>
      </section>

      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
      />
    </div>
  );
};

export default Faq;
