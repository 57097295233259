import React, { useState, useEffect } from "react";
import Select from "react-select";
import useApiRequest from "../../api/ApiRequest";
import api from "../../api/Api";
import userService from "../../services/userService";

export const CountryCodeSelect = React.memo((props) => {
    const request = useApiRequest();
    const { isPaddingTop, isPaddingBottom } = props;
    const [data, setData] = useState([]);

    const fetchCountryData = async () => {
        // try {
        //     const response = await request({
        //         method: "get",
        //         url: api.COUNTRY_LIST,
        //     });

        //     if (response?.status === true && response?.status_code === 200) {
        //         return response.data;
        //     } else {
        //         return [];
        //     }
        // } catch (error) {
        //     console.error("Error in API request:", error);
        //     return [];
        // }

        try {
            const response = await userService.countryCode();
            if (response?.status === true && response?.status_code === 200) {
                return response.data;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchData = async () => {
        const dataArray = await fetchCountryData();

        if (dataArray) {
            const newArray = dataArray.map((item) => {
                return { // Log the phonecode here
                    label: "+" + item.phonecode,
                    value: item.phonecode,
                }
            });
            setData(newArray);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Select
            components={{ IndicatorSeparator: () => null }}
            value={{ label: "+" + props?.value }}
            defaultValue="+1"
            options={data}
            styles={{
                control: (styles) => ({
                    ...styles,
                    paddingTop: isPaddingTop ? isPaddingTop : 5,
                    paddingBottom: isPaddingBottom ? isPaddingBottom : 4,
                    paddingLeft: 0,
                    paddingRight: 0,
                    width: 89,
                }),
                dropdownIndicator: (styles) => ({ ...styles, padding: 0 }),
                singleValue: (styles) => ({ ...styles, color: "#000", fontWeight: "normal" }),
            }}
            name={props?.name}
            id={props?.id}
            onChange={({ value }) => {
                let event = { target: { name: props?.name, value: value } };
                props?.onSelect(event);
            }}
        />
    );
});
