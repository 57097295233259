import React, { useEffect, useRef, useState } from "react";
import api from "../../api/Api";
import { Formik } from "formik";
import useApiRequest from "../../api/ApiRequest";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/slice/ToastReducer";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../constant";

const ContactUs = () => {
  const request = useApiRequest();
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [contactUsData, setContactUsData] = useState([]);
  const dispatch = useDispatch();
  const reCaptchaRef = useRef(null);

  useEffect(() => {
    // Get Header Data
    request({
      method: "get",
      url: api.HEADER_FOOTER,
    })?.then((response) => {
      setHeaderFooterData(response);
    });

    // Get Contact Us Page Details
    request({
      method: "get",
      url: api.CONTACT_US_PAGE,
    })?.then((response) => {
      setContactUsData(response[0]);
    });
  }, []);

  // Contact form submit
  const onFormSubmit = (value, resetForm, setSubmitting) => {
    setSubmitting(true);
    const contactForm = new FormData();
    Object.keys(value).forEach((key) => contactForm.append(key, value[key]));
    request({
      method: "post",
      url: api.CONTACT_FORM_SUBMIT(contactUsData?.acf?.contact_form_id),
      data: contactForm,
    })?.then((response) => {
      resetForm();
      if (response?.invalid_fields?.length == 0) {
        dispatch(showToast({ type: "success", message: response.message }));
        setSubmitting(false);
      }
    });
  };

  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <section className="contact-us  header-space ">
        <div className="container">
          <h1 className="section-heading">{contactUsData?.acf?.contact_us_title}</h1>
          <p className="section-sub-title">{contactUsData?.acf?.subtitle}</p>
          <div style={{ marginTop: -25, marginBottom: 15 }} dangerouslySetInnerHTML={{ __html: contactUsData?.content?.rendered }}></div>
          <div className="contact-us-container d-flex">
            <div className="contact-form-container">
              <Formik
                initialValues={{
                  recaptcha: "",
                  "first-name": "",
                  "last-name": "",
                  "your-email": "",
                  "your-message": "",
                }}
                validationSchema={yup.object().shape({
                  recaptcha: yup.string().required(),
                  "first-name": yup
                    .string()
                    .trim()
                    .max(15, "First name can not be more than 15 characters.")
                    .required("Please fill first name field."),
                  "last-name": yup
                    .string()
                    .trim()
                    .max(15, "Last name can not be more than 15 characters.")
                    .required("Please fill last name field."),
                  "your-email": yup.string().email("Must be a valid email").required("Please fill email field."),
                  "your-message": yup.string().max(1000, "Comment can not be more than 1000 characters."),
                })}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { resetForm, setSubmitting }) => onFormSubmit(values, resetForm, setSubmitting)}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  setFieldValue,
                  setSubmitting,
                  isSubmitting,
                }) => {
                  const onHandleBlur = (e) => {
                    if (!values.recaptcha) {
                      reCaptchaRef.current.execute();
                      setSubmitting(true);
                    }
                    handleBlur(e);
                  };
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="common-form-row two-col-row justify-content-space-between d-flex">
                        <div className="common-form-cell">
                          <label htmlFor="">First Name</label>
                          <div className="input-box">
                            <Input
                              type="text"
                              name="first-name"
                              onBlur={onHandleBlur}
                              onChange={handleChange}
                              value={values["first-name"]}
                            />
                            {errors["first-name"] && <div className="form-error-msg">{errors["first-name"]}</div>}
                          </div>
                        </div>
                        <div className="common-form-cell">
                          <label htmlFor="">Last Name</label>
                          <div className="input-box">
                            <Input
                              type="text"
                              name="last-name"
                              onBlur={onHandleBlur}
                              onChange={handleChange}
                              value={values["last-name"]}
                            />
                            {errors["last-name"] && <div className="form-error-msg">{errors["last-name"]}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="common-form-row">
                        <div className="common-form-cell">
                          <label htmlFor="UserId" className="m-b-5">
                            Email Address
                          </label>
                          <div className="input-with-icon">
                            <Input
                              type="text"
                              name="your-email"
                              onChange={handleChange}
                              onBlur={onHandleBlur}
                              value={values["your-email"]}
                              id="UserId"
                              placeholder="Enter Your Email Id"
                            />
                            <div className="input-icon user-icon"></div>
                          </div>
                          {errors["your-email"] && <div className="form-error-msg">{errors["your-email"]}</div>}
                        </div>
                      </div>
                      <div className="common-form-row d-flex">
                        <div className="common-form-cell">
                          <label htmlFor="UserId" className="m-b-5">
                            Comment
                          </label>
                          <div className="input-box">
                            <textarea
                              className="contact-textarea"
                              onChange={handleChange}
                              onBlur={onHandleBlur}
                              value={values["your-message"]}
                              name="your-message"
                              id=""
                              cols="30"
                              rows="5"
                            ></textarea>
                            {errors["your-message"] && <div className="form-error-msg">{errors["your-message"]}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="contact-form-cta d-flex align-center ">
                        <ReCAPTCHA
                          size="invisible"
                          ref={reCaptchaRef}
                          sitekey={recaptchaKey?.sitekey}
                          onChange={(value) => {
                            setFieldValue("recaptcha", value);
                            setSubmitting(false);
                          }}
                        />
                        <Button className="common-btn" type="submit" disabled={isSubmitting}>
                          Submit
                        </Button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="contact-us-address">
              <div
                className="h-100 map-wrapper"
                dangerouslySetInnerHTML={{ __html: contactUsData?.acf?.google_map_location }}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
      />
    </div>
  );
};

export default ContactUs;
