import React from "react";
import Button from "../../../components/Button";
import ScrollAnimation from "react-animate-on-scroll";
import { animateScroll as scroll } from "react-scroll";

const BannerSection = ({ content = {}, setshowVideo, showVideo }) => {
  return (
    <section
      className="home-banner animatedParent"
      style={{
        backgroundImage: `url(${content?.banner_background_image ? content?.banner_background_image : "/assets/images/banner.jpeg"
          })`,
        marginTop: showVideo ? "100vh" : "0",
      }}
    >
      <div className="container">
        <div className="banner-content-box">
          {/* <h4>{content?.sub_title}</h4> */}
          <h1 className="banner-title" dangerouslySetInnerHTML={{ __html: content?.first_title }} />
          <h1 className="banner-title second-title" dangerouslySetInnerHTML={{ __html: content?.second_title }} />
          {/* <p dangerouslySetInnerHTML={{ __html: content?.description }} /> */}
          <div className="banner-cta">
            <img className="video-image" src="assets/images/video-image.png" />
            <Button
              onClick={() => {
                setshowVideo(true);
                scroll.scrollToTop();
              }}
            >
              {content?.banner_button?.title}
              <div className="banner-button-subtitle">{content?.button_sub_title}</div>
            </Button>
          </div>
        </div>
      </div>
      <div className="count-section">
        <ul className="d-flex align-center">
          {content?.banner_counter?.map((counter, index) => {
            return (
              <li key={index}>
                <ScrollAnimation duration={2} animateIn="animate__zoomIn" initiallyVisible animateOnce>
                  <strong>
                    <span data-value={counter?.counter} id={`counter${index}`}>
                      0
                    </span>
                    {index === 1 ? " Million" : index === 2 ? "% Increase" : "%"}
                  </strong>
                  <span>{counter?.title}</span>
                </ScrollAnimation>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
export default BannerSection;
