import React, { useState } from "react";
import { InputLabel } from "../InputLabel";
export const PhoneInput = (props) => {
    const {
        label = "",
        style = {},
        id = "",
        isDisabled = false,
        onChange = () => { },
        errorMessage = "",
        onBlur = () => { },
        showOptional = false,
        placeholder = "",
        value = null,
        showErrorMessage = true,
        maxLength = 50,
    } = props;
    const [error, setError] = useState("");
    const handlePhoneChange = (event) => {
        let phone = event.target.value;

        // Remove any non-digit characters
        phone = phone.replace(/[^0-9]/g, "");
        if (phone[0] === "0") {
            return;
        }

        setError("");
        let e = { target: { name: id, value: phone } };
        onChange(e);
    };

    const handlePhoneBlur = (event) => {
        let phone = event.target.value;

        // Remove any non-digit characters
        phone = phone.replace(/[^0-9]/g, "");
        if (phone[0] === "0") {
            setError("Invalid Number");
            return;
        }

        // Check if phone number is at least 10 digits long
        if (phone.length < 10) {
            setError("Invalid Number");
        }
        let e = { target: { name: id, value: "" } };
        onBlur(e);
    };

    return (
        <>
            {/* {label ? <InputLabel id={id} label={label} showOptional={showOptional} /> : null} */}
            <div className="input-box w-100" id={id}>
                <input
                    type="tel"
                    value={value}
                    onChange={handlePhoneChange}
                    onBlur={handlePhoneBlur}
                    disabled={isDisabled}
                    style={style}
                    id={id}
                    name={id}
                    placeholder={placeholder}
                    maxLength={maxLength}
                />
                {(errorMessage || error) && showErrorMessage && <InputError error={errorMessage || error} />}
            </div>
        </>
    );
};