import { configureStore } from "@reduxjs/toolkit";
import toast from "./slice/ToastReducer";

const store = configureStore({
  reducer: {
    toast: toast,
  },
});

export default store;
