import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import "../../css/style.css";
import useApiRequest from "../../api/ApiRequest";
import api from "../../api/Api";
import Footer from "../../components/Footer";
import BannerSection from "./sections/BannerSection";
import HowItWorksSection from "./sections/HowItWorksSection";
import TelescienceSection from "./sections/TelescienceSection";
import WhySynaptical from "./sections/WhySynaptical";
import "animate.css/animate.min.css";
import ServicesSection from "../../components/sections/ServicesSection";
import SessionCostSection from "../../components/sections/SessionCostSection";
import PartnershipSection from "./sections/PartnershipSection";
import InspiringStoriesSection from "./sections/InspiringStoriesSection";
import ConsultTelescientist from "./sections/ConsultTelescientist";
import ReactPlayer from "react-player";
import OurTelescientists from "./sections/OurTelescientists";
import CeoMessageSection from "./sections/CeoMessageSection";

const Home = () => {
  const request = useApiRequest();
  const vidRef = useRef();
  const slider = useRef(null);
  const firstSlider = useRef(null);
  const howItWorkRef = useRef(null);
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [homePageData, sethomePageData] = useState([]);
  const [sliderCount, setSliderCount] = useState(0);
  const [state, setState] = useState({
    pip: false,
    playing: false,
    controls: false,
    light: false,

    muted: true,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    volume: 1,
    loop: false,
    seeking: false,
  });
  const [showVideo, setshowVideo] = useState(false);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    request({
      method: "get",
      url: api.HEADER_FOOTER,
    })?.then((response) => {
      setHeaderFooterData(response);
    });

    request({
      method: "get",
      url: api.HOME_PAGE,
    })?.then((response) => {
      sethomePageData(response[0]?.acf);
      setTimeout(function () {
        numberCount(document.getElementById("counter0"));
        numberCount(document.getElementById("counter1"));
        numberCount(document.getElementById("counter2"));
      }, 2000);
    });

    request({
      method: "get",
      url: api.PLAN_PRICING,
    })?.then((response) => {
      setPageData(response);
    });

    setTimeout(() => {
      if (window?.location?.hash != "#how-it-work") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }, 400);
  }, []);

  useEffect(() => {
    if (howItWorkRef && howItWorkRef?.current && window?.location?.hash == "#how-it-work") {
      setTimeout(() => howItWorkRef?.current?.scrollIntoView({ behavior: "smooth" }), 1000);
    }
  }, []);

  useEffect(() => {
    if (showVideo) {
      setTimeout(() => {
        vidRef.current.seekTo(parseFloat(0 / 100), "fraction");
        setState({ ...state, playing: true });
      }, 700);
    } else {
      setState({ ...state, playing: false });
    }
  }, [showVideo]);

  const handlePlayPause = () => {
    // vidRef.current.seekTo(parseFloat(e.target.value / 100), "fraction");
    setState({ ...state, playing: !state?.playing });
  };

  const numberCount = (containerName) => {
    let runningNum = 0;
    var totalValue = containerName?.dataset?.value;
    if (totalValue > 200) {
      runningNum = totalValue - 100;
    }
    const counterInterval = setInterval(function () {
      if (runningNum < totalValue) {
        ++runningNum;
        if (containerName) {
          containerName.innerHTML = runningNum;
        }
      } else {
        if (containerName) {
          containerName.innerHTML = totalValue;
        }
      }
    }, 10);
    if (runningNum == Math.round(totalValue)) {
      clearInterval(counterInterval);
    }
  };

  const handleSeekChange = (e) => {
    setState({ ...state, played: parseFloat(e.target.value / 100), seeking: false });
    vidRef.current.seekTo(parseFloat(e.target.value / 100), "fraction");
  };

  const handleSeekMouseDown = (e) => {
    setState({ ...state, seeking: true });
  };

  const handleProgress = (changeState) => {
    if (changeState?.played == 1) {
      setshowVideo(false);
    }
    if (!state.seeking) {
      setState({ ...state, ...changeState });
    }
  };

  const onSliderChange = () => {
    if (slider?.current && firstSlider.current) {
      const activeIndex = firstSlider.current.swiper.activeIndex - 1;
      const slideCount = 2; // Number of slides in the second slider that correspond to each slide in the first slider
      const targetIndex = Math.floor(activeIndex / slideCount);

      slider.current.swiper.slideTo(targetIndex);
    }
  };

  useEffect(() => {
    if (slider?.current && firstSlider.current) {
      const activeIndex = sliderCount;
      const slideCount = 2; // Number of slides in the second slider that correspond to each slide in the first slider
      const targetIndex = Math.floor(activeIndex / slideCount);

      slider.current.swiper.slideTo(targetIndex);
    }
  }, [sliderCount]);

  return (
    <div>
      <Header logo={headerFooterData?.site_logo?.url} content={headerFooterData} />
      <div className="video-player-container" style={{ right: showVideo ? "0" : "-100vw" }}>
        <div className="video-player-wrapper">
          <ReactPlayer
            ref={vidRef}
            url={homePageData?.home_page_video}
            width="100%"
            height="100%"
            playing={state?.playing}
            playbackRate={state?.playbackRate}
            onProgress={handleProgress}
          />
          <div className="player-control">
            {state?.playing ? (
              <img
                className="play-pause-icon"
                alt=""
                src="assets/images/icons/Pause.svg"
                onClick={() => handlePlayPause()}
              />
            ) : (
              <img
                className="play-pause-icon"
                alt=""
                src="assets/images/icons/Play.svg"
                onClick={() => handlePlayPause()}
              />
            )}

            <div className="player-progress-bar">
              <input
                className="video-progress-bar"
                type="range"
                min={0}
                max={100}
                value={state?.played * 100}
                onChange={handleSeekChange}
                onMouseDown={handleSeekMouseDown}
              />
            </div>
          </div>
          <img
            className="close-icon"
            alt=""
            src="assets/images/icons/close-icon.svg"
            onClick={() => setshowVideo(false)}
          />
        </div>
      </div>
      <BannerSection content={homePageData?.banner_section} setshowVideo={setshowVideo} showVideo={showVideo} />
      <WhySynaptical
        content={homePageData?.why_synaptical_section}
        is_beta={headerFooterData?.beta_registration}
        story={homePageData?.inspiring_stories_section}
        setSliderCount={setSliderCount}
        firstSlider={firstSlider}
        onSliderChange={onSliderChange}
      />
      <ServicesSection content={pageData?.service_section} />
      <HowItWorksSection content={homePageData?.how_it_works_section} is_beta={headerFooterData?.beta_registration} eleRef={howItWorkRef} />
      <TelescienceSection content={homePageData?.telescience_section} />
      <OurTelescientists />
      <SessionCostSection content={pageData?.plans_table} is_beta={headerFooterData?.beta_registration} />
      <PartnershipSection content={homePageData?.partnership_logos_section} />
      <InspiringStoriesSection content={homePageData?.inspiring_stories_section} is_beta={headerFooterData?.beta_registration} slider={slider} />
      <CeoMessageSection content={homePageData?.message_from_ceo_section_setting} />
      <ConsultTelescientist content={homePageData?.home_consult_scientists_section} is_beta={headerFooterData?.beta_registration} />
      <Footer
        logo={headerFooterData?.site_logo?.url}
        leftContent={headerFooterData?.footer_left_side_content}
        copyRightText={headerFooterData?.footer_copyright_text}
        socialIcons={headerFooterData?.site_social_icons}
        copyRightMenu={headerFooterData?.footer_copyright_menu}
        footerMenu={headerFooterData?.footer_right_side_content?.menus}
        newsLetterContent={headerFooterData?.footer_right_side_content?.news_latter}
        poweredBy={headerFooterData?.powered_by}
      />
    </div>
  );
};

export default Home;
