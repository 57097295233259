import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import Button from "../Button";
const SessionCostSection = ({ content, is_beta }) => {
  const isBooleanRegistration = is_beta ?? false

  return (
    <section className="HT-cost">
      <div className="container ">
        <h3 className="section-heading">{content?.title}</h3>
        <p className="feature-description">{content?.description}</p>
        <div className="ht-cost-container d-flex animatedParent">
          {content?.session_cost_table?.map((table, index) => (
            <ScrollAnimation
              className="ht-cost-pack"
              duration={2}
              animateOnce
              animateIn={`${index == 0 ? "animate__bounceInLeft" : index == 1 ? "animate__zoomIn" : "animate__bounceInRight"
                }`}
              key={index}
            >
              <div className="ht-cost-header">
                <h5>{table?.title}</h5>
                {/* <div className="ht-pack-price">
                  <sup>$</sup>
                  <strong>{table?.price_per_session}</strong>
                </div>
                <div className="ht-costPP">per session</div> */}
              </div>
              <div className="ht-cost-body">
                <h6>Features</h6>
                <ul>
                  {table?.features?.map((feature, findex) => (
                    <li key={findex}>{feature?.feature_title}</li>
                  ))}
                </ul>
              </div>
              {/* <div className="ht-cost-cta">
                <Button onClick={() => window.open(table?.button_text?.url)}>{table?.button_text?.title}</Button>
              </div> */}
            </ScrollAnimation>
          ))}
          <div className="session-button-wrapper">
            <p className="ht-cost-note">{content?.sub_title}</p>
            <Button onClick={isBooleanRegistration && Boolean(JSON.parse(isBooleanRegistration)) ? () => window.open('/beta-user-registration') : () => window.open(content?.join_now_button_url)}>{content?.join_now_button_text}</Button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SessionCostSection;
