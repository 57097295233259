import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideToast } from "../redux/slice/ToastReducer";

const PopUpTypes = {
  error: "error-msg",
  success: "success-msg",
};

const Popup = () => {
  const loaderRef = useRef("");
  const { show, type = "success-msg", message = "hello" } = useSelector((state) => state.toast);
  const dispatch = useDispatch();
  useEffect(() => {
    if (show) {
      loaderRef.current?.classList.remove("d-none");
    } else {
      loaderRef.current?.classList.add("d-none");
    }

    setTimeout(() => {
      if (show) {
        dispatch(hideToast());
      }
    }, 3000);
  }, [show]);

  return (
    <div ref={loaderRef} className={`status-message-box ${PopUpTypes[type]}`}>
      <strong>{type.toUpperCase()}</strong>
      <p>{message}</p>
      <div className="close-message" onClick={() => dispatch(hideToast())}>
        CLOSE
      </div>
    </div>
  );
};

export default Popup;
